import { tv } from "tailwind-variants";

export const buttonVariant = tv({
  variants: {
    state: {
      normal: "",
      pressed: "",
      disabled: "",
      secondiary: ""
    },
    type: {
      fill: "",
      outline: "tw-border-1 tw-border-solid"
    },
    theme: {
      primary: "",
      secondary: "",
      tertiary: ""
    },
    size: {
      small: "tw-w-half tw-h-[44px]",
      large: "tw-w-full tw-h-[52px]"
    }
  },
  base: "tw-rounded tw-flex tw-items-center tw-justify-center tw-text-center tw-cursor-pointer tw-w-full tw-px-20 tw-body4 tw-px-3 tw-py-2 tw-text-base",
  defaultVariants: {
    type: "fill",
    state: "normal",
    theme: "primary",
    size: "large"
  },
  compoundVariants: [
    {
      type: "fill",
      theme: "primary",
      state: "normal",
      class:
        "tw-bg-socarElecleBlue500 tw-text-white active:tw-bg-socarElecleBlue600 active:tw-text-white"
    },
    {
      type: "fill",
      theme: "primary",
      state: "disabled",
      class: "tw-bg-gray200 tw-text-gray400"
    },
    {
      type: "fill",
      theme: "secondary",
      state: "normal",
      class:
        "tw-bg-socarElecleBlue100 tw-text-socarElecleBlue500 active:tw-bg-socarElecleBlue200 active:tw-text-socarElecleBlue500"
    },
    {
      type: "fill",
      theme: "secondary",
      state: "disabled",
      class: "tw-bg-gray200 tw-text-gray400"
    },
    {
      type: "fill",
      theme: "tertiary",
      state: "normal",
      class:
        "tw-bg-gray100 tw-text-gray500 active:tw-bg-gray200 active:tw-text-gray500"
    },
    {
      type: "fill",
      theme: "tertiary",
      state: "disabled",
      class: "tw-bg-gray200 tw-text-gray400"
    },
    {
      type: "outline",
      theme: "secondary",
      state: "normal",
      class:
        "tw-bg-white tw-text-socarElecleBlue500 tw-border-socarElecleBlue500 active:tw-bg-gray100 active:tw-text-socarElecleBlue600 active:tw-border-socarElecleBlue600"
    },
    {
      type: "outline",
      theme: "secondary",
      state: "disabled",
      class: "tw-bg-white tw-text-gray400 tw-border-gray300"
    },

    {
      type: "outline",
      theme: "tertiary",
      state: "normal",
      class:
        "tw-bg-white tw-text-gray500 tw-border-gray300 active:tw-bg-gray100 active:tw-text-gray500 active:tw-border-gray400"
    },
    {
      type: "outline",
      theme: "tertiary",
      state: "disabled",
      class: "tw-bg-white tw-text-gray300 tw-border-gray300"
    }
  ]
});
