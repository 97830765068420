/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isDefined } from "../empty";
import convertStringToType from "../string/converStringToType";

const queryToParams = (
  queries: Record<string, any>,
  keys: string[] = [],
  filterKeys: string[] = []
) => {
  if (!queries) return {};
  let query: Record<string, string> = {};
  const defaultKeys = ["page"];
  const allKeys = Object.keys(queries);

  const targetKeys = keys.length === 0 ? allKeys : [...defaultKeys, ...keys]; // keys가 빈배열이라면 모든 값을 포함, 아니라면, 들어온 keys만 포함

  const filterToJson = (
    queries: Record<string, any>,
    fillterKeys: string[]
  ) => {
    let filter: Record<string, any> = {};
    fillterKeys.forEach((key) => {
      let value = null;
      const isArrayValue = Array.isArray(queries[key]);
      if (isArrayValue) {
        value = queries[key]
          .map((item: string) => convertStringToType(item))
          .join(",");
      } else {
        value = convertStringToType(queries[key]);
      }

      filter[key] = value;
    });

    return JSON.stringify(filter);
  };

  const transformArrayValueToString = (queryKey: string, queryValue: any[]) => {
    const obj: Record<string, any> = {};
    queryValue.length > 0 &&
      (obj[queryKey] = queryValue
        .filter((value) => isDefined(value))
        .toString());
    // 배열에서 값이 있는 것만 필터링해서 문자열로 변환

    return obj;
  };

  for (const [queryKey, queryValue] of Object.entries(queries)) {
    if (!queryValue) continue;
    const hasFilterKeys = filterKeys?.includes(queryKey);
    const hasParamsKeys = targetKeys.includes(queryKey);
    const isValueArray = Array.isArray(queryValue);

    if (hasParamsKeys || hasFilterKeys) {
      if (isValueArray && !hasFilterKeys) {
        const arrayValueToString = transformArrayValueToString(
          queryKey,
          queryValue
        );
        query = { ...query, ...arrayValueToString }; // 배열에서 값이 있는 것만 필터링해서 문자열로 변환
      } else if (hasFilterKeys) {
        const fillterKeysHasValues = filterKeys.filter((key) =>
          isDefined(queries[key])
        );

        const filter = filterToJson(queries, fillterKeysHasValues);
        query.filter = filter;
      } else {
        // @ts-ignore
        query[queryKey] = queryValue.toString();
      }
    }
  }

  return query;
};

export default queryToParams;
