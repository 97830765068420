import { v1 } from "uuid";

import {
  SHOW_CLUSTER_MARKER_ZOOM,
  SHOW_MARKER_ZOOM,
  STOP_DISPLAY_GEOBLOCK_ZOOM_LEVEL
} from "./constant";
import NaverMap from "./NaverMap";
import {
  Bike,
  BikeCluster,
  Geoblock,
  InfoWindowMarker
} from "../../interfaces";

class NaverDashboardMap extends NaverMap {
  public clusterMarkers: Record<string, naver.maps.Marker> = {};
  public bikeMarkers: Record<string, naver.maps.Marker> = {};
  public bikeWindowInfos: Record<string, naver.maps.InfoWindow> = {};
  public selectedBikeMarker: naver.maps.Marker | null = null;

  private markerDrawIdentifier: string | null = null; // 마커를 그리는 요청을 취소하기 위한 flag 값

  constructor() {
    super();
  }

  /**
   * @description 중심에서 부터, 지도의 꼭지점까지의 거리를 km로 구합니다.
   * @returns {number} km 단위의 거리
   * @memberof NaverDashboardMap
   */
  getRadius() {
    const center = this.map!.getCenter();
    const centerCoord = new naver.maps.LatLng(center?.y, center?.x);
    const bounds = this.map!.getBounds() as naver.maps.LatLngBounds;
    const northEast = bounds.getNE();
    const distanceByMeter =
      this.map?.getProjection()?.getDistance(centerCoord, northEast) ?? 0;

    return distanceByMeter / 1000;
  }

  /**
   * @description 현재 지도의 zoom level을 구합니다.
   * @returns {number} 현재 지도의 zoom level
   * @memberof NaverDashboardMap
   */
  getZoomLevel() {
    return this.map?.getZoom();
  }

  /**
   * @description 현재 지도의 중심 좌표를 구합니다.
   * @returns {number[]} 현재 지도의 중심 좌표
   * @memberof NaverDashboardMap
   */
  getCenter() {
    return [this.map?.getCenter().x, this.map?.getCenter().y];
  }

  /**
   * @description 현재 지도의 줌 값에 따라, eps 값을 산출합니다.
   * @param {number} currentZoom: 현재 지도의 줌 값
   * @memberof NaverDashboardMap
   * @returns eps(클러스터로 취급할 점과 점 사이의 거리)
   */
  getZoomToEps(currentZoom: number) {
    const STANDARD_ZOOM = 16; // 줌 16 레벨이 기본이라고 가정
    const STANDARD_EPS = 0.16; // 줌 16 레벨일 때 eps 값

    const STOP_CLUSTERING_ZOOM = 9;
    const MAX_EPS = 20;

    const level = STANDARD_ZOOM - currentZoom;
    const calculatedEps = STANDARD_EPS * 2 ** level;

    return currentZoom <= STOP_CLUSTERING_ZOOM ? MAX_EPS : calculatedEps;
  }

  /**
   * @description 클러스터 api 호출시 사용할 필터 옵션을 구합니다.
   * @returns {object} 클러스터 api 호출시 사용할 필터 옵션
   */
  getFilterOptions() {
    const zoomLevel = this.getZoomLevel();
    if (!zoomLevel) return;
    return {
      eps: this.getZoomToEps(zoomLevel),
      position: this.getCenter() ?? [],
      radius: this.getRadius(),
      zoom: this.getZoomLevel()
    };
  }

  /**
   * @description 클러스터 마커의 uuid를 구합니다.
   * @param {BikeCluster} bikeCluster: 클러스터 정보
   * @returns {string} 클러스터 마커의 uuid
   */
  getClusterUuid(bikeCluster: BikeCluster) {
    return bikeCluster.count + "-" + bikeCluster.marker_location.join(",");
  }

  /**
   * @description 클러스터 갯수에 따라 마커의 크기를 결정합니다.
   * @param {number} bikeClusterCount: 클러스터 갯수
   * @returns {number} 마커의 크기
   * @memberof NaverDashboardMap
   */
  getSizeOfClusterMarker(bikeClusterCount: number) {
    return bikeClusterCount < 10
      ? 35
      : bikeClusterCount < 20
      ? 40
      : bikeClusterCount < 50
      ? 45
      : bikeClusterCount < 100
      ? 50
      : bikeClusterCount < 500
      ? 55
      : 60;
  }
  /**
   * @description 줌레벨에 따라, 클러스터 마커와 바이크 마커용 데이터를 분류합니다.
   * @param {BikeCluster[]} clusterGroup: 클러스터 정보
   * @returns {clusters: BikeCluster[], bikes: Bike[]} 분류된 클러스터 데이터와 바이크 데이터
   * @memberof NaverDashboardMap
   */
  classifyCluterGroupAndBikesByZoom(clusterGroup: BikeCluster[]) {
    if (!clusterGroup) return { clusters: [], bikes: [] };
    const zoomLevel = this.getZoomLevel() as number;
    const clusterAndBikes: { clusters: BikeCluster[]; bikes: Bike[] } = {
      clusters: [],
      bikes: []
    };

    if (zoomLevel >= SHOW_MARKER_ZOOM) {
      clusterAndBikes.bikes = clusterGroup.flatMap((cluster) =>
        cluster.cluster.map((bike) => bike)
      );
    }

    if (zoomLevel < SHOW_MARKER_ZOOM && zoomLevel >= SHOW_CLUSTER_MARKER_ZOOM) {
      // SHOW_MARKER_ZOOM 이상 SHOW_CLUSTER_MARKER_ZOOM 미만이면 1개 클러스터링 그룹은 바이크 마커로 표시하고 나머지는 클러스터 마커로 표시
      clusterAndBikes.clusters = clusterGroup.filter(
        (cluster) => cluster.count > 1
      );

      clusterAndBikes.bikes = clusterGroup
        .filter((cluster) => cluster.count === 1)
        .flatMap((cluster) => cluster.cluster.map((bike) => bike));
    }

    if (zoomLevel < SHOW_CLUSTER_MARKER_ZOOM) {
      clusterAndBikes.clusters = clusterGroup;
    }

    return clusterAndBikes;
  }

  /**
   * @description 윈도우가 달린 바이크 마커를 그리고, bikeMarkers와 bikeWindowInfos에 할당합니다. 마커를 클릭하면 선택되며, clickCallback이 실행됩니다.
   * @param {Bike} bike: 바이크 정보
   * @param {(bike: Bike) => void} [clickCallback]: 마커 클릭시 실행할 콜백 함수
   * @param {string} [currentIdentifier]: 현재 그리기 작업의 식별자
   * @returns {naver.maps.Marker} 그려진 바이크 마커
   * @memberof NaverDashboardMap
   */
  setBikeMarker(
    bike: Bike & { isSelected?: boolean },
    clickCallback?: (bike: Bike) => void,
    currentIdentifier?: string
  ) {
    if (currentIdentifier && this.markerDrawIdentifier !== currentIdentifier) {
      return;
    }
    const uuid = bike.sn;

    if (this.bikeMarkers[uuid]) return; // 중복으로 그리는 것 방지
    const item: InfoWindowMarker = {
      uuid: bike.sn,
      position: bike.location,
      info: `${bike.sn}`,
      markerType: "bike_status",
      vendor: bike.vendor,
      status: bike.status,
      isSelected: bike.isSelected
    };
    const marker = this.setWindowInfoMarker(
      item,
      this.bikeMarkers,
      this.bikeWindowInfos
    );
    this.bikeMarkers[uuid] = marker;

    this.addMarkerClickEvent(marker, () => {
      clickCallback && clickCallback(bike);

      const prevMarker = this.selectedBikeMarker;
      prevMarker && this.updateIconNotSelected(prevMarker);

      marker && this.updateIconSelected(marker);
      this.selectedBikeMarker = marker;
    });

    return marker;
  }

  /**
   * @description 바이크 마커의 아이콘을 selected가 아닌 상태로 변경합니다.
   * @param {naver.maps.Marker} marker: 바이크 마커
   * @memberof NaverDashboardMap
   */
  updateIconNotSelected(marker: naver.maps.Marker) {
    const prevMarker = marker;
    const icon = prevMarker?.getIcon() as naver.maps.ImageIcon;

    icon?.url &&
      prevMarker?.setIcon({
        url: icon?.url!.replace("-selected", ""),
        scaledSize: new naver.maps.Size(20, 20)
      });
  }

  /**
   * @description 바이크 마커의 아이콘을 selected 상태로 변경합니다.
   * @param {naver.maps.Marker} marker: 바이크 마커
   * @memberof NaverDashboardMap
   */
  updateIconSelected(marker: naver.maps.Marker) {
    const prevMarker = marker;
    const icon = prevMarker?.getIcon() as naver.maps.ImageIcon;

    icon?.url &&
      prevMarker?.setIcon({
        url: icon?.url!.replace(".png", "-selected.png"),
        scaledSize: new naver.maps.Size(20, 20)
      });
  }

  /**
   * @description 선택된 바이크 정보로, 마커를 업데이트합니다. 마커가 없다면 새로 그리고, 있다면 정보를 갱신합니다.
   * @param {Bike} bike: 바이크 정보
   * @param {(bike: Bike) => void} [callBack]: 마커 클릭시 실행할 콜백 함수
   */
  updateSelectedBike(
    bike: Bike & { isSelected: boolean },
    callBack?: (bike: Bike) => void
  ) {
    const setZoomIn = () => {
      const filterOptions = this.getFilterOptions();
      if (!filterOptions) return;
      const { zoom } = filterOptions;
      if (zoom && zoom < SHOW_MARKER_ZOOM)
        this.map?.setZoom(SHOW_MARKER_ZOOM, true);
    };

    const setCenterToBike = () => {
      const position = this.getLatLngFromPosition(bike.location);
      this.map?.setCenter(position);
    };

    const updateUnselectPrevMarker = () => {
      const prevSelected = this.selectedBikeMarker;
      prevSelected && this.updateIconNotSelected(prevSelected);
    };

    const makeNewMarker = () => {
      const newMarker = this.setBikeMarker(
        {
          ...bike,
          isSelected: true
        },
        callBack
      );
      if (newMarker) {
        this.selectedBikeMarker = newMarker;
        this.updateIconSelected(newMarker);
        newMarker.setZIndex(1);
        const newWindowInfo = this.bikeWindowInfos[bike.sn];
        newWindowInfo && this.openWindow(newMarker, newWindowInfo);
      }
    };

    const updateMarker = (marker: naver.maps.Marker) => {
      const icon = NaverMap.getMapIcon({
        ...bike,
        markerType: "bike_status",
        isSelected: true
      });
      const position = this.getLatLngFromPosition(bike.location);
      marker.setIcon(icon);
      marker.setPosition(position);
      marker.setZIndex(1);
      this.selectedBikeMarker = marker;
      const windowInfo = this.bikeWindowInfos[bike.sn];
      windowInfo && this.openWindow(marker, windowInfo);
    };

    const updateMarkerByExist = () => {
      const marker = this.bikeMarkers[bike.sn];

      if (!marker) {
        makeNewMarker();
      } else {
        updateMarker(marker);
      }
      this.selectedBikeMarker && openSelectedWindow(this.selectedBikeMarker);
    };

    const openSelectedWindow = (marker: naver.maps.Marker) => {
      const timeout = setTimeout(() => {
        const windowInfo = this.bikeWindowInfos[bike.sn];
        windowInfo && this.openWindow(marker, windowInfo);
        clearTimeout(timeout);
      }, 1000);
    };

    setCenterToBike();
    setZoomIn();
    updateUnselectPrevMarker();
    updateMarkerByExist();
  }

  /**
   * @description 제너레이터 함수로 바이크 마커들을 그립니다.
   * @param {Bike[]} bikes: 바이크 정보 목록
   * @param {(bike: Bike) => void} [clickCallback]: 마커 클릭시 실행할 콜백 함수
   * @param {string} [currentIdentifier]: 현재 그리는 마커의 식별자
   * @memberof NaverDashboardMap
   */
  *setBikeMarkers(
    bikes: Bike[],
    clickCallback?: (bike: Bike) => void,
    currentIdentifier?: string
  ) {
    let index = 0;

    while (index < bikes.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const bike = bikes[index];
        this.setBikeMarker(bike, clickCallback, currentIdentifier);
        index++;
        yield;
      } else {
        break;
      }
    }
  }

  /**
   * @description 제너레이터 함수로 새로운 바이크 리스트를 받아, 지울 대상을 선별하고, 바이크 마커를 제거합니다.
   * @param {Bike[]} newBikes: 새로운 바이크 정보 목록
   * @param {string} [currentIdentifier]: 현재 그리는 마커의 식별자
   * @memberof NaverDashboardMap
   */
  *removeBikeMarkersComparedToNewBikes(
    newBikes: Bike[],
    currentIdentifier?: string
  ) {
    let index = 0;
    const previousBikeMarkerLength = Object.keys(this.bikeMarkers).length;
    const removeTargetUuids = Object.keys(this.bikeMarkers).filter(
      (uuid) => !newBikes.find((bike) => bike.sn + "" === uuid)
    );

    while (index < previousBikeMarkerLength) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const uuid = removeTargetUuids[index];

        this.removeMarker(uuid, this.bikeMarkers, this.bikeWindowInfos);
        index++;
        yield;
      } else {
        break;
      }
    }
  }

  /**
   * @description 클러스터 마커를 그리고, clusterMarkers에 할당합니다.
   * @param {BikeCluster} bikeCluster: 클러스터 정보
   * @param {string} [currentIdentifier]: 마커 그리기 식별자
   * @returns {naver.maps.Marker} 그려진 클러스터 마커
   * @memberof NaverDashboardMap
   */
  setClusterMarker(bikeCluster: BikeCluster, currentIdentifier?: string) {
    if (currentIdentifier && this.markerDrawIdentifier !== currentIdentifier) {
      return;
    }

    const uuid = this.getClusterUuid(bikeCluster);
    if (this.clusterMarkers[uuid]) return; // 중복으로 그리는 것 방지
    const marker = this.drawMarker({
      position: bikeCluster.marker_location,
      markerType: "clustering",
      count: bikeCluster.count,
      isSelected: false,
      size: this.getSizeOfClusterMarker(bikeCluster.count)
    });

    this.addMarkerClickEvent(marker, () => {
      this.setCenter(bikeCluster.marker_location);
      this.zoomIn(); // 클러스터 마커 클릭시, 지도 확대
    });

    this.clusterMarkers[uuid] = marker;
    return marker;
  }

  /**
   * @description 제너레이터 함수로 클러스터 마커들을 그리고, clusterMarkers에 할당합니다.
   * @param {BikeCluster[]} bikeClusters: 클러스터 정보 목록
   * @memberof NaverDashboardMap
   */
  setClusterMarkers(bikeClusters: BikeCluster[], currentIdentifier?: string) {
    let index = 0;

    while (index < bikeClusters.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const bikeCluster = bikeClusters[index];
        this.setClusterMarker(bikeCluster, currentIdentifier);
        index++;
      } else {
        break;
      }
    }
  }

  /**
   * @description 새로운 클러스터링 리스트를 받아, 지울 대상을 선별하고, 클러스터링 마커를 제거합니다.
   * @param {string} uuid: 클러스터링 마커의 uuid
   * @memberof NaverDashboardMap
   */
  removeClusterMarkersComparedToNewClusters(
    newClusters: BikeCluster[],
    currentIdentifier?: string
  ) {
    const newClusterUuids = newClusters.map((cluster) => {
      return this.getClusterUuid(cluster);
    });

    const removeTargetUuids = Object.keys(this.clusterMarkers).filter(
      (uuid) => !newClusterUuids.includes(uuid)
    );

    let index = 0;
    while (index < removeTargetUuids.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const uuid = removeTargetUuids[index];
        this.removeMarker(uuid, this.clusterMarkers);
        index++;
      } else {
        break;
      }
    }
  }

  /**
   * @description 클러스터링 리스트를 받아, 바이크 마커와, 클러스터 마커를 업데이트합니다.
   * @param {BikeCluster[]} bikeClusterList: 클러스터링 리스트
   * @param {(bike: Bike) => void} setSeletedBike: 선택된 바이크 정보를 업데이트하는 함수
   * @memberof NaverDashboardMap
   */
  updateBikeAndClusterMarkers = (
    bikeClusterList: BikeCluster[],
    setSeletedBike: (bike: Bike) => void
  ) => {
    const { bikes, clusters } =
      this.classifyCluterGroupAndBikesByZoom(bikeClusterList); // 클러스터링 대상이 아닌 자전거들만 가져오기
    this.removeClusterMarkersComparedToNewClusters(clusters);
    this.cancelAndExcuteMarkerDraw(bikes, clusters, setSeletedBike);
  };

  /**
   * @description 바이크와 클러스터 마커를 제너레이터로 지연시켜 그립니다.
   * @param bikes 바이크 리스트
   * @param clusters 클러스터 리스트
   * @param setSeletedBike 선택된 바이크 정보를 업데이트하는 함수
   * @memberof NaverDashboardMap
   */
  cancelAndExcuteMarkerDraw(
    bikes: Bike[],
    clusters: BikeCluster[],
    setSeletedBike: (bike: Bike) => void
  ) {
    const currentIdentifier = v1();
    this.markerDrawIdentifier = currentIdentifier; // 새로운 마커를 그리기 위해, 현재의 identifier를 저장합니다.

    const removeBikeIterator = this.removeBikeMarkersComparedToNewBikes(
      bikes,
      currentIdentifier
    );

    const setBikeInterator = this.setBikeMarkers(
      bikes,
      setSeletedBike,
      currentIdentifier
    );

    const makeExcuteIteration =
      (iterable: Generator<undefined, void, unknown> | null) => () => {
        const done = iterable?.next()?.done;

        if (!done && this.markerDrawIdentifier === currentIdentifier) {
          setTimeout(makeExcuteIteration(iterable), 0);
        } else {
          iterable = null;
          return;
        }
      };

    makeExcuteIteration(removeBikeIterator)();
    makeExcuteIteration(setBikeInterator)();

    this.removeClusterMarkersComparedToNewClusters(clusters, currentIdentifier);
    this.setClusterMarkers(clusters, currentIdentifier);
  }

  /**
   * @description geoblock 목록과 필터(줌 레벨 포함)을 받아, 반납구역을 지우고 다시 그립니다.
   * @param geoblocks 반납구역 리스트
   * @returns
   */
  setGeoblockByZoom(geoblocks: Geoblock[]) {
    const filterOptions = this.getFilterOptions();
    if (!filterOptions) return;

    const { zoom } = filterOptions;

    if (zoom && zoom < STOP_DISPLAY_GEOBLOCK_ZOOM_LEVEL) {
      this.removeAllGeoblocks();
      return;
    }

    if (geoblocks) {
      const removeTargetIds = this.getRemoveTargetGeoblockIds(geoblocks);
      this.removeGeoblockList(removeTargetIds);
    }

    this.setGeoblocks(geoblocks);
  }

  /**
   * @description 모든 바이크 마커를 제거하고, 초기화합니다.
   * @memberof NaverDashboardMap
   */
  getRemoveAllBikeMarkers() {
    Object.keys(this.bikeMarkers).forEach((key) => {
      this.bikeMarkers[key].setMap(null);
      delete this.bikeMarkers[key];
    });
  }

  /**
   * @description 지도에 센터가 바뀌면 콜백을 실행합니다.
   * @param {(args?: any) => void} f: 콜백 함수
   * @memberof NaverDashboardMap
   */
  addEventListenerByCenterChange(f: (args?: any) => void) {
    naver.maps.Event.addListener(this.map, "center_changed", f);
  }

  /**
   * @description 지도에 줌이 바뀌면 콜백을 실행합니다.
   * @param {(args?: any) => void} f: 콜백 함수
   * @memberof NaverDashboardMap
   */
  addEventListerByZoomChange(f: (args?: any) => void) {
    naver.maps.Event.addListener(this.map, "zoom_changed", f);
  }

  /**
   * @description 모든 바이크 마커의 윈도우를 닫습니다.
   * @memberof NaverDashboardMap
   */
  public closeAllBikeMarkerInfoWindows() {
    Object.values(this.bikeWindowInfos).forEach((info) => info.close());
  }

  /**
   * @description 줌 레벨에 따라, 반납구역을 지우고 다시 그립니다.
   * @param {Geoblock[]} [geoblockList] 반납구역 리스트
   * @memberof NaverDashboardMap
   */
  public updateGeoblocks(geoblockList?: Geoblock[]) {
    const filterOptions = this.getFilterOptions();
    if (!filterOptions) return;
    const { zoom } = filterOptions;

    if (zoom && zoom < STOP_DISPLAY_GEOBLOCK_ZOOM_LEVEL) {
      this.removeAllGeoblocks();
      return;
    }

    if (!geoblockList) return;

    const removeTargetIds = this.getRemoveTargetGeoblockIds(geoblockList);
    this.removeGeoblockList(removeTargetIds);
    this.setGeoblocks(geoblockList);
  }

  /**
   * @description 지도를 클릭하면, 모든 바이크 마커의 윈도우를 닫습니다.
   * @memberof NaverDashboardMap
   */
  closeWindowByClickMapEvent(clickCallback?: () => void) {
    naver.maps.Event.addListener(this.map, "click", () => {
      this.closeAllBikeMarkerInfoWindows();
      this.selectedBikeMarker &&
        this.updateIconNotSelected(this.selectedBikeMarker);
      clickCallback && clickCallback();
    });
  }
}

export default NaverDashboardMap;
