import {
  CurrentMapStatus,
  EditablePolygon,
  Geoblock,
  MapOption,
  PolygonOption,
  SelectedStatus
} from "../../interfaces";

import { DEFAULT_MAP_OPTION, DEFAULT_WAITIMG_POLYGON_OPTION } from "./constant";
import NaverDrawingManagerMap from "./NaverDrawingManagerMap";
import NaverMap from "./NaverMap";

class NaverGeoblockMap extends NaverMap {
  public editingPolygon: naver.maps.Polygon | null = null;
  public selectedPolygon: naver.maps.Polygon | null = null;
  public editingPolygonId: string | null = null;
  public selectedPolygonId: string | null = null;
  public multiSelectedPolygons: string[] = [];
  public geojson: any = null;
  public addingPolygon: naver.maps.Polygon | null = null;
  public drawingManager: NaverDrawingManagerMap | null = null;
  public prevEditingPolygon: EditablePolygon | null = null;

  constructor() {
    super();
  }

  /**
   * @description 반납구역 네이버 지도를 생성합니다.
   * @param {string | HTMLElement} elem  생성 할 지도의 DOM Element
   * @param {naver.maps.MapOptions} option
   * @param {()=>void)} callback
   * @memberof NaverGeoblockMap
   */
  public setMap(
    elem: string | HTMLElement,
    option?: naver.maps.MapOptions,
    callback?: () => void
  ): void {
    this.map = new naver.maps.Map(elem, {
      ...DEFAULT_MAP_OPTION,
      ...option,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_LEFT
      }
    });

    this.drawingManager = new NaverDrawingManagerMap(this.map);

    callback && callback();
  }

  /**
   * @description 단일선택 다중선택 이벤트를 가진 반납구역을 생성합니다.
   * @param {Geoblock[]} geoblocks 반납구역 정보
   * @param {PolygonOption} options 폴리곤 옵션
   * @memberof NaverGeoblockMap
   */
  public setEditableGeoblocks(
    geoblocks: Geoblock[],
    options: Partial<PolygonOption> & { clickable?: boolean },
    clickCallbck: (e: MouseEvent) => void
  ) {
    this.setGeoblocks(geoblocks, options, (e, polygon, polygon_id) => {
      this.selectedPolygon = polygon;
      this.selectedPolygonId = String(polygon_id);
      clickCallbck && clickCallbck(e);
    });
  }

  /**
   * @description 폴리곤 라인 굵기를 조정합니다.
   * @param {naver.maps.Polygon} polygon
   * @param {number} weight
   * @memberof NaverMap
   */
  private updatePolygonStrokeWeight(
    polygon: naver.maps.Polygon,
    weight: number
  ) {
    polygon.setOptions("strokeWeight", weight);
  }

  /**
   * @description 폴리곤이 다른 폴리곤과 다른지 비교합니다.
   * @param {naver.maps.Polygon} targetPolygon
   * @param {naver.maps.Polygon} comparablePolygon
   * @returns {boolean}
   * @memberof NaverMap
   */
  private isDifferentPolygon(
    targetPolygon: naver.maps.Polygon,
    comparablePolygon: naver.maps.Polygon
  ) {
    // @ts-ignore
    const targetPolygonPath = targetPolygon.getPath()["_array"].toString();
    // @ts-ignore
    const comparablePolygonPath = comparablePolygon
      .getPath()
      ["_array"].toString();

    return !(targetPolygonPath === comparablePolygonPath);
  }

  /**
   *
   * @description 수정중인 폴리곤인지 체크합니다.
   * @returns {boolean}
   */
  public isEdited() {
    if (!this.prevEditingPolygon || !this.editingPolygon) return false;
    return this.isDifferentPolygon(
      this.prevEditingPolygon,
      this.editingPolygon
    );
  }

  /**
   * @description 수정중 폴리곤을 초기화합니다.
   * @returns {boolean}
   * @memberof NaverGeoblockMap
   */
  public initializeEditing() {
    if (this.editingPolygon && this.drawingManager) {
      this.drawingManager?.removeEditablePolygon(this.editingPolygon);
    }
    this.editingPolygon = null;
    this.editingPolygonId = null;
    this.prevEditingPolygon = null;
  }

  /**
   * @description 다중선택된 폴리곤들을 초기화합니다.
   * @memberof NaverMap
   */
  public initializeMultiselection() {
    this.multiSelectedPolygons.forEach((polygon_id) => {
      this.geoblockPolygons[polygon_id]?.setOptions("strokeWeight", 0);
    });
    this.multiSelectedPolygons = [];
  }

  /**
   * @description 단일 추가 반납구역 제거합니다.
   */
  public removeAddingPolygon() {
    if (!this.addingPolygon || !this.drawingManager) return;
    this.drawingManager.removeEditablePolygon(this.addingPolygon);
    this.addingPolygon = null;
  }

  /**
   * @description 인수로 넘겨받은 폴리곤의 드래그를 막습니다.
   * @param {naver.maps.Polygon} polygon 드래그를 막을 폴리곤
   */
  public preventDragablePolygon(polygon: naver.maps.Polygon) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    polygon.addListener("mousedown", (e) => {
      e?.originalEvent?.stopPropagation();
      e?.originalEvent?.preventDefault();
    });
  }

  /**
   * @description 단일 선택 시 수정가능한 폴리곤을 생성합니다.
   * @param {string} polygon_id
   * @memberof NaverMap
   */
  public handleSingleSelection() {
    if (!this.selectedPolygon || !this.drawingManager) return;
    this.editingPolygonId = this.selectedPolygonId;
    // @ts-ignore
    const paths = this.selectedPolygon.getPath()["_array"];
    const _paths = paths.slice(0, paths.length - 1);
    const forkEditablePolygon: EditablePolygon =
      this.drawingManager.forkEditablePolygon(
        _paths,
        this.preventDragablePolygon
      );

    this.prevEditingPolygon = new naver.maps.Polygon({
      paths: _paths
    });
    this.editingPolygon = forkEditablePolygon;
    forkEditablePolygon.trigger("click");
  }

  /**
   * @description 폴리곤의 앵커들에 이벤트를 설정합니다.
   * @param {naver.maps.Polygon} forkEditablePolygon 앵커를 가진 폴리곤
   */
  public setAnchorClickEvent(
    mode: "editing" | "adding" = "editing",
    polygon: EditablePolygon
  ) {
    const anchors = polygon._controlPoints as { _shapeElement: HTMLElement }[];
    anchors.forEach((anchor, index) => {
      anchor._shapeElement.addEventListener(
        "mouseup",
        function (e: MouseEvent) {
          const modifiedPolygon = this.anchorRightClick(e, polygon, index);
          if (!modifiedPolygon) return;
          if (mode === "editing") {
            this.editingPolygon = modifiedPolygon;
          } else {
            this.addingPolygon = modifiedPolygon;
          }
        }.bind(this)
      );
    });
  }

  /**
   * @description 앵커 우측클릭 이벤트를 설정합니다
   * @param {naver.maps.MouseEvent} e 마우스 이벤트
   * @param {naver.maps.Polygon} editingPolygon 수정중인 폴리곤
   * @param {number} index 앵커 인덱스
   */
  public anchorRightClick(
    e: MouseEvent,
    editingPolygon: naver.maps.Polygon,
    index: number
  ) {
    if (e.button !== 2) {
      return editingPolygon;
    }
    if (!confirm("선택된 앵커를 삭제하시겠습니까?")) {
      editingPolygon.trigger("click");
      return editingPolygon;
    }
    if (!editingPolygon || !this.map || !this.drawingManager) return;
    // @ts-ignore
    const paths = editingPolygon.getPath()["_array"];
    // @ts-ignore
    const bounds = editingPolygon._controlPoints[index]._bounds;
    const latlng = this.map
      .getProjection()
      .fromOffsetToCoord(new naver.maps.Point(bounds));
    this.drawingManager.removeEditablePolygon(editingPolygon);

    const removeIndex = this.getClosestPointIndex(paths, latlng);
    if (removeIndex !== -1) {
      paths.splice(removeIndex, 1);
    }

    const modifiedPolygon = this.drawingManager.forkEditablePolygon(
      paths,
      this.preventDragablePolygon
    );

    modifiedPolygon.trigger("click");
    this.map.setOptions("draggable", true);
    return modifiedPolygon;
  }

  /**
   * @description 폴리곤 패스의 좌표와 선택된 자표에서 가장 가까운 인덱스를 반환합니다.
   * @param {string[]} paths 폴리곤 패스의 좌표
   * @param {string[]} coord 선택된 좌표
   * @returns {number} 값이 가장 가까운 인덱스
   * @memberof NaverMap
   */
  public getClosestPointIndex(
    paths: naver.maps.LatLng[],
    coord: naver.maps.Coord
  ) {
    let minDiff = 100000;
    let removeIndex = -1;

    paths.forEach((path: naver.maps.LatLng, index: number) => {
      const xDiff = Math.abs(path.x - coord.x);
      const yDiff = Math.abs(path.y - coord.y);
      const totalDiff = xDiff + yDiff;

      if (totalDiff < minDiff) {
        minDiff = totalDiff;
        removeIndex = index;
      }
    });

    return removeIndex;
  }

  /**
   * @description Geojson으로 반납구역을 생성합니다.
   * @param {Geoblock[]} geoJson geojson 데이터
   * @param {PolygonOption} options 폴리곤 옵션
   * @memberof NaverGeoblockMap
   */
  public setGeoJsonData(geoJson: any, options?: PolygonOption) {
    if (!this.map) return;
    this.geojson = geoJson;
    this.map.data.addGeoJson(geoJson, true);
    this.map.data.setStyle(function (f) {
      const _options = options ?? {
        ...DEFAULT_WAITIMG_POLYGON_OPTION
      };

      return _options;
    });
  }

  /**
   * @description Geojson 반납구역을 제거합니다.
   * @param {Geoblock[]} geoJson geojson 데이터
   * @param {PolygonOption} options 폴리곤 옵션
   * @memberof NaverGeoblockMap
   */
  public removeGeojosnData() {
    if (!this.map) return;
    this.map.data.removeGeoJson(this.geojson);
    this.geojson = null;
  }

  /**
   * @description 폴리곤을 다중선택하며, 중복폴리곤은 제거합니다.
   */
  public setMultiSelection = () => {
    const {
      selectedPolygon,
      editingPolygonId,
      selectedPolygonId,
      multiSelectedPolygons
    } = this;

    if (!selectedPolygonId || !selectedPolygon) return;

    const isDuplicated = multiSelectedPolygons.includes(
      selectedPolygonId as string
    );

    if (editingPolygonId) {
      const prevSelectedPolygon = this.geoblockPolygons[editingPolygonId];
      this.updatePolygonStrokeWeight(prevSelectedPolygon, 3);
      multiSelectedPolygons.push(editingPolygonId);
      this.initializeEditing();
    }

    if (isDuplicated) {
      const index = multiSelectedPolygons.indexOf(selectedPolygonId);
      this.updatePolygonStrokeWeight(selectedPolygon, 0);
      multiSelectedPolygons.splice(index, 1);
    } else {
      this.updatePolygonStrokeWeight(selectedPolygon, 3);
      multiSelectedPolygons.push(selectedPolygonId);
    }
  };

  /**
   * @description 클릭 시 일어날 액션을 반환합니다. (단일선택, 다중선택, 다중선택취소, 추가취소)
   * @param {any} e
   * @returns {SelectedStatus}
   * @memberof NaverGeoblockMap
   */
  public getClickAction(e?: any) {
    const currentMode = this.getMode();
    const isShiftPressed = e?.originalEvent?.shiftKey;
    let nextMove: SelectedStatus = "SingleSelect";

    if (currentMode === "isEditing") nextMove = "Editing";
    if (currentMode === "isMultiSelecting" && !isShiftPressed)
      nextMove = "CancelMultiSelect";
    if (
      (currentMode === "isMultiSelecting" && isShiftPressed) ||
      isShiftPressed
    )
      nextMove = "MultiSelect";
    if (
      currentMode === "isAddingSinglePolygon" ||
      currentMode === "isAddingGeoJsonPolygon"
    )
      nextMove = "CancelAddingPolygon";

    return nextMove;
  }

  /**
   * @description 현재 지도의 상태를 반환합니다. (아무선택되지 않음, 다중선택중, 수정중, 개별폴리곤 추가중, geojson 추가중)
   * @returns {CurrentMapStatus}
   */
  public getMode() {
    let mode: CurrentMapStatus = "isClear";
    const isMultiSelected = this.multiSelectedPolygons.length > 0;

    if (isMultiSelected) mode = "isMultiSelecting";
    if (this.selectedPolygon && this.editingPolygon) mode = "isEditing";
    if (this.addingPolygon) mode = "isAddingSinglePolygon";
    if (this.geojson) mode = "isAddingGeoJsonPolygon";

    return mode;
  }

  public setGeoblockClickable(clickable: boolean) {
    const polygons = Object.values(this.geoblockPolygons);
    polygons.forEach((value) => {
      value.setOptions("clickable", clickable);
    });
  }

  public destroy() {
    if (this.drawingManager) {
      this.drawingManager.destroy();
      this.drawingManager = null;
    }
    if (this.map) {
      this.map.destroy();
      this.map = undefined;
    }
    this.editingPolygon = null;
    this.selectedPolygon = null;
    this.editingPolygonId = null;
    this.selectedPolygonId = null;
    this.multiSelectedPolygons = [];
    this.geoblockPolygons = {};
    this.geojson = null;
    this.addingPolygon = null;
  }
}

export default NaverGeoblockMap;
