import { MapPolyline, PolygonOption, Position } from "../../interfaces";
import { NaverMapUtility } from "./NaverMapUtility";
import { DEFAULT_POLYLINE_OPTION } from "./constants/map";

export class NaverPolyline implements MapPolyline {
  public ridingPathPolyline: naver.maps.Polyline | null = null;
  private map: naver.maps.Map;

  constructor(map: naver.maps.Map) {
    this.map = map;
  }

  /**
   * @description 폴리라인을 그립니다.
   * @param {Position[]} positions: 폴리라인을 그릴 좌표 목록
   * @returns {naver.maps.Polyline}
   * @memberof NaverMap
   */
  public drawPolyline(positions: Position[], polylineOption?: PolygonOption) {
    return new naver.maps.Polyline({
      map: this.map,
      path: positions.map((position) =>
        NaverMapUtility.getLatLngFromPosition(position)
      ),
      ...DEFAULT_POLYLINE_OPTION,
      ...polylineOption
    });
  }

  /**
   * @description 지도에 라이딩 경로를 그리고, ridingPathPolyline에 할당합니다.
   * @param {Position[]} positions: 라이딩 경로 좌표 목록
   * @memberof NaverMap
   */
  public setRidingPathPolyline(positions: Position[]) {
    this.ridingPathPolyline = this.drawPolyline(positions);
  }

  /**
   * @description 지도에 그려진 라이딩 경로를 제거합니다.
   * @memberof NaverMap
   */
  public removeRidingPathPolyline() {
    this.ridingPathPolyline?.setMap(null);
    this.ridingPathPolyline = null;
  }
}
