const getItemsFromObject = (obj: Record<string, any>, keys: string[]) => {
  const newObj: Record<string, any> = {};
  for (const key of keys) {
    if (obj[key] !== undefined) {
      // null, "", 0, false are valid values
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export default getItemsFromObject;
