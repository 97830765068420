import { getNumberOrPixelVal } from "shared-utils";

import {
  ButtonProperty,
  ButtonProps,
  ButtonState,
  ButtonTheme,
  ButtonType
} from "../../types/Button";

import { buttonVariant } from "./buttonVariant";
import { Typography } from "../Typography/Typography";
import { SpinnerIcon } from "../Icon/SpinnerIcon";
import React from "react";

export const Button = ({
  width,
  height,
  borderRadius = 8,
  style,
  className,
  size = "large",
  buttonType = "button-fill-normal-primary",
  leftUnit,
  rightUnit,
  children,
  textVariant = "body4",
  loading,
  disabled,
  onClick,
  as = "button",
  href
}: ButtonProps) => {
  const widthVal = getNumberOrPixelVal(width);
  const heightVal = getNumberOrPixelVal(height);
  const borderRadiusVal = getNumberOrPixelVal(borderRadius);
  const isDisabled = disabled || loading;

  const setButtonType = (): ButtonProperty => {
    const _buttonProperties = buttonType.split("-");
    if (isDisabled) _buttonProperties[2] = "disabled";
    return {
      type: _buttonProperties[1] as ButtonType,
      state: _buttonProperties[2] as ButtonState,
      theme: _buttonProperties[3] as ButtonTheme
    };
  };

  const { state, type, theme }: ButtonProperty = setButtonType();

  const As = as;

  return (
    <div>
      <As
        style={{
          width: widthVal,
          height: heightVal,
          borderRadius: borderRadiusVal,
          ...style
        }}
        className={buttonVariant({
          state,
          type,
          size,
          theme,
          className
        })}
        disabled={state === "disabled" || isDisabled}
        onClick={onClick}
        href={href}
      >
        {leftUnit && <div className="tw-mr-4">{leftUnit}</div>}
        <Typography variant={textVariant}>{children}</Typography>
        {loading && <SpinnerIcon />}
        {rightUnit && <div className="tw-ml-4">{rightUnit}</div>}
      </As>
    </div>
  );
};
