export const isValidTableRows = (rows?: string) => {
  try {
    if (!rows) return true;
    const data = JSON.parse(rows);
    if (!Array.isArray(data)) return false;

    const hasId = data.every((d) => {
      return d.id !== undefined;
    });
    if (!hasId) return false;

    return true;
  } catch (e) {
    return false;
  }
};
