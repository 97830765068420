import getTimeInDigits from "./getTimeInDigits";

export const getTimeDifferenceByUnit = (
  timeDifference: number,
  timeUnit: "seconds" | "minutes" | "hours" | "days",
  as?: string
) => {
  const time = timeCalculation[timeUnit](timeDifference);

  if (as === "number") return time;
  return getTimeInDigits(time);
};

export const getTimeDifferenceAllUnits = (timeDifference: number) => {
  return {
    seconds: getTimeDifferenceByUnit(timeDifference, "seconds"),
    minutes: getTimeDifferenceByUnit(timeDifference, "minutes"),
    hours: getTimeDifferenceByUnit(timeDifference, "hours"),
    days: getTimeDifferenceByUnit(timeDifference, "days")
  };
};

const timeCalculation = {
  seconds: (timeDifference: number) => Math.floor((timeDifference / 1000) % 60),
  minutes: (timeDifference: number) =>
    Math.floor((timeDifference / (1000 * 60)) % 60),
  hours: (timeDifference: number) =>
    Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
  days: (timeDifference: number) =>
    Math.floor(timeDifference / (1000 * 60 * 60 * 24))
};
