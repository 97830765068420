type Area = {
  name?: string;
  code: string;
};

const transformArea = (areas: Area[], areaString: string) => {
  const area = areas.find((area) => area.code == areaString);
  return area?.name || "";
};

export default transformArea;
