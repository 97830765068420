export const POLYGON_OPTION_ACTIVE = {
  fillColor: "#ff2100",
  fillOpacity: 0.3,
  strokeColor: "#ff2100",
  strokeOpacity: 0.6,
  strokeWeight: 1
};

export const POLYGON_OPTION_INACTIVE = {
  fillColor: "#000000",
  fillOpacity: 0.3,
  strokeColor: "#000000",
  strokeOpacity: 0.6,
  strokeWeight: 1
};
