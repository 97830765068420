export const REFUND_TYPE = [
  {
    value: 0,
    name: "고장"
  },
  {
    value: 1,
    name: "반납지연"
  },
  { value: 50, name: "거치대할인" },
  { value: 100, name: "기타" }
];
