import { FieldErrors, FieldValues } from "react-hook-form";

type ErrorWithMessage<T extends FieldValues> = FieldErrors<T>;

const getMsgFromFieldErrors = <T extends FieldValues>(
  errors: ErrorWithMessage<T>
): string | undefined => {
  if (errors.root) return errors.root.message;

  const error = Object.values(errors)[0];
  if (error?.message) {
    return error.message as string;
  }

  if (typeof error === "object") {
    return getMsgFromFieldErrors(error as T);
  }
};

export default getMsgFromFieldErrors;
