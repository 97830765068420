import { MapCreateDestroyer } from "../../interfaces";

export class NaverCreatorDestroyer implements MapCreateDestroyer {
  map: naver.maps.Map | null = null;

  setMap(elem: string | HTMLElement, option: naver.maps.MapOptions): void {
    this.map = new naver.maps.Map(elem, option);
  }

  destroy(): void {
    if (this.map) {
      this.map.destroy();
      this.map = null;
    }
  }

  getMap() {
    return this.map;
  }
}
