export const IMAGE_MARKER_URL = "https://cdn.elecle.bike/assets/etc";
export const DEFAULT_WAITIMG_POLYGON_OPTION = {
  fillColor: "#FFC700",
  strokeColor: "#FFC700",
  fillOpacity: 0.3,
  StrokeOpacity: 0.6,
  strokeWeight: 1
};

export const DEFAULT_DRAWINGMAGAGER_ANCHORS = {
  position: {} as naver.maps.Position,
  anchorPointOptions: {
    radius: 7,
    fillColor: "#ff0000",
    strokeColor: "#0000ff",
    strokeWeight: 2,
    center: { x: 0.5, y: 0.5 }
  },
  midPointOptions: {
    radius: 6,
    fillColor: "#ff0000",
    strokeColor: "#0000ff",
    strokeWeight: 2,
    fillOpacity: 0.1,
    center: { x: 0.5, y: 0.5 }
  }
};

export const SHOW_MARKER_ZOOM = 17;
export const SHOW_CLUSTER_MARKER_ZOOM = 16;

export const DEFAULT_MAP_OPTION: naver.maps.MapOptions = {
  zoom: 14,
  zoomControl: false
};

export const DEFAULT_POLYGON_OPTION = {
  fillColor: "#ff2100",
  fillOpacity: 0.3,
  strokeColor: "#ff2100",
  strokeOpacity: 0.6,
  strokeWeight: 1
} as naver.maps.PolygonOptions;


export const DEFAULT_POLYLINE_OPTION = {
  fillColor: "#007EEA",
  fillOpacity: 0.3,
  strokeColor: "#007EEA",
  strokeOpacity: 0.6,
  strokeWeight: 3
};


