const transformReserveStatusString = (status: string) => {
  if (status === "RESERVED") {
    return "예약중";
  } else if (status === "CANCELED") {
    return "예약 후 취소";
  } else if (status === "DONE") {
    return "예약 후 라이딩";
  } else {
    return "";
  }
};

export default transformReserveStatusString;
