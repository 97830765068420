export type Region = {
  code: string;
  name: string;
};

const transformRegion = (regions: Region[], regionString: string) => {
  const region = regions.find((region) => region.code == regionString);
  return region?.name;
};

export default transformRegion;
