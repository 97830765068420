import { FieldValues, FormState } from "react-hook-form";

export function dirtyValues(
  dirtyFields:
    | Partial<Readonly<Record<string, boolean | boolean[] | unknown>>>
    | boolean,
  allValues: Record<string, unknown>
): object {
  // false인 값은 제외
  function filterObject(obj: Record<string, unknown> | boolean) {
    const newObject: {
      [key: string]: any;
    } = {};

    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        const isAtLeastOneItemDirty = value.some((item) => {
          return item;
        });
        if (isAtLeastOneItemDirty) {
          newObject[key] = value.map(() => true); // 배열의 경우 하나라도 바뀌면 모두 true가 되도록
        }
      } else if (typeof value === "object" && value !== null) {
        const filteredValue = filterObject(value);
        if (Object.keys(filteredValue).length > 0) {
          newObject[key] = filteredValue;
        }
      } else if (value) {
        newObject[key] = value;
      }
    }

    return newObject;
  }

  const getDirtyValues = (
    dirtyFields: FormState<FieldValues>["dirtyFields"] | boolean,
    allValues: Record<string, unknown>
  ): any => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        // @ts-ignore
        getDirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };
  return getDirtyValues(filterObject(dirtyFields), allValues);
}
