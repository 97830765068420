type FormData = Record<string, any>;
export type InputsData = Record<
  string,
  {
    name: string;
    label: string;
    options?: { label: string; value: string }[];
    fieldType?: string;
    hidden?: boolean;
    valueIfHidden?: unknown;
  }
>;

const getConfirmMessage = (
  data: FormData,
  inputs: InputsData,
  title: string = "",
  description: string = "",
  depth: number = 0
): string => {
  const message = Object.keys(inputs).reduce((acc, key) => {
    const input = inputs[key];
    const value = data[key];
    const label = input?.label || key;

    if (!label || value === undefined) {
      return acc;
    }

    let formattedValue;

    if (input.options) {
      // if value is array (multiple select)
      if (Array.isArray(value)) {
        const options = input.options.filter((option) =>
          value.includes(option.value)
        );
        formattedValue = options.map((option) => option.label).join(", ");
      } else {
        // if value is string (select or radio or checkbox or autocomplete)
        const option = input.options.find((option) => option.value === value);
        formattedValue = option?.label ?? "";
      }
    } else if (Object.prototype.toString.call(value) === "[object Date]") {
      formattedValue = value.toISOString();
    } else if (Array.isArray(value)) {
      if (value.length > 30) {
        // 표시할 수 있는 최대 개수를 30개로 보고, 넘어가면 개수만 표시
        formattedValue = value.length + "개";
      } else {
        formattedValue = value.join(", ");
      }
    } else if (value === null) {
      formattedValue = `${value}`;
    } else if (typeof value === "object") {
      if (input?.fieldType) {
        formattedValue = JSON.stringify(value);
      } else {
        formattedValue = getConfirmMessage(
          value,
          input as unknown as InputsData,
          undefined,
          undefined,
          depth + 1
        );
      }
    } else {
      formattedValue = value;
    }

    return `${acc}${"\u00A0\u00A0".repeat(depth)}${label}: ${formattedValue}\n`;
  }, "");

  return `${title}\n${message}${description ? `\n${description}` : ""}`.replace(
    /\n\n/g,
    "\n"
  );
};

export default getConfirmMessage;
