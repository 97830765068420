export const isVersionLessThan = (
  currentVersion: string,
  targetVersion: string
): boolean => {
  const current = currentVersion.split(".").map(Number);
  const compare = targetVersion.split(".").map(Number);

  const maxLength = Math.max(current.length, compare.length);

  for (let i = 0; i < maxLength; i++) {
    const part1 = current[i] || 0;
    const part2 = compare[i] || 0;

    if (part1 < part2) {
      return true;
    } else if (part1 > part2) {
      return false;
    }
  }

  return false; // 버전이 동일할 때
};
