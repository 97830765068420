type OriginalType = "string" | "number" | "boolean";

type ReturnTypeMap = {
  string: string;
  number: number;
  boolean: boolean;
};

// Supported types: number, boolean, string
function transformValToOriginalType<T extends OriginalType>(
  fieldValue: unknown,
  originalType: T
): ReturnTypeMap[T] {
  if (originalType === "number") {
    return Number(fieldValue) as ReturnTypeMap[T];
  }

  if (originalType === "boolean") {
    return Boolean(
      fieldValue === "true" || fieldValue === true
    ) as ReturnTypeMap[T];
  }

  if (originalType === "string") {
    return String(fieldValue) as ReturnTypeMap[T];
  }

  return fieldValue as ReturnTypeMap[T];
}

export default transformValToOriginalType;
