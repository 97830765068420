const convertStringToType = (value: string) => {
  if (!isNaN(Number(value))) {
    return Number(value);
  }

  if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
    return value.toLowerCase() === "true";
  }

  return value;
};

export default convertStringToType;
