import { v4 } from "uuid";
import { _window } from "../enviroment/windowWithSSR";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkit: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AndroidInterface: any;
    nativeCallback: NativeCallback;
    callbacks: any;
  }
}

type NativeCallback = (envelop: WebBridgeEnvelop) => void;

type StringValue = {
  value: NonNullable<unknown>;
};

export enum ErrorCode {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  DESERIALIZE_FAIL = "DESERIALIZE_FAIL",
  SERIALIZE_FAIL = "SERIALIZE_FAIL",
  UNKNOWN_ACTION = "UNKNOWN_ACTION"
}

type WebBridgeError = {
  code: ErrorCode;
  message: string;
  extra?: {
    [extraKey: string]: string;
  };
};

export type WebBridgeEnvelop = {
  requestCode: number;
  action: string;
  error?: WebBridgeError;
  body?: StringValue;
};

_window.callbacks = {};

_window.nativeCallback = (envelop: WebBridgeEnvelop): void => {
  const { requestCode } = envelop;
  const callback = _window.callbacks[requestCode];

  if (callback) {
    if (envelop?.error) {
      callback(envelop);
      throw new Error(envelop.error.code + " " + envelop?.error.message);
    } else if (envelop.body) {
      const envelopWithParsedBody: WebBridgeEnvelop = Object.assign(envelop, {
        body: {
          value: JSON.parse(String(envelop.body.value))
        }
      });
      callback(envelopWithParsedBody);
    }
    callback(envelop);
  }
  delete _window.callbacks[requestCode];
};

const calliOS = (
  callbackID: string,
  command: string,
  data?: Record<string, unknown>
): void => {
  const stringifiedEnvelop = {
    requestCode: String(callbackID),
    command: command,
    ...(data && {
      body: {
        value: JSON.stringify(data)
      }
    })
  };

  _window.webkit.messageHandlers?.iOSInterface?.postMessage(stringifiedEnvelop);
};

const callAndroid = (
  callbackID: string,
  command: string,
  data?: Record<string, unknown>
): void => {
  const stringifiedEnvelop = JSON.stringify({
    requestCode: callbackID,
    command: command,
    ...(data && {
      body: {
        value: JSON.stringify(data)
      }
    })
  });
  _window.AndroidInterface?.postAction(stringifiedEnvelop);
};

type NotEmpty<T> = Record<string, unknown> extends T ? any : T;

export type CallApp<A = string, T = unknown> = (
  command: A,
  data: NotEmpty<T>,
  options?: any
) => Promise<any>;

export const callApp: CallApp = (command, data, options?) => {
  const requestCode = v4();

  const bridgePromise = new Promise((resolve, reject) => {
    _window.callbacks[requestCode] = function (response: WebBridgeEnvelop) {
      if (response.error) reject(response);
      if (response) resolve(response);
    };

    if (navigator.userAgent.includes("elecle-android")) {
      callAndroid(requestCode, command, data);
    }
    if (navigator.userAgent.includes("elecle-ios")) {
      calliOS(requestCode, command, data);
    }
  });
  return bridgePromise;
};
