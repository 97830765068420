import {
  Geoblock,
  InfoWindowMarker,
  MarkerInfo,
  PolygonOption,
  Position
} from "../../interfaces";

import {
  DEFAULT_MAP_OPTION,
  DEFAULT_POLYGON_OPTION,
  DEFAULT_POLYLINE_OPTION,
  IMAGE_MARKER_URL
} from "./constant";

class NaverMap {
  public map?: naver.maps.Map;
  public geoblockPolygons: Record<string, naver.maps.Polygon>;

  constructor() {
    this.geoblockPolygons = {};
  }

  /**
   * @description 네이버 지도를 생성합니다.
   * @param {string | HTMLElement} elem: 지도를 표시할 HTML element 또는 element의 id
   * @param {MapOption} option: 지도 옵션
   * @memberof NaverMap
   */
  public setMap(elem: string | HTMLElement, option = {}) {
    this.map = new naver.maps.Map(elem, {
      ...DEFAULT_MAP_OPTION,
      ...option,
      zoomControlOptions: {
        // 줌 컨트롤 위치 (포함 안할시 네이버 맵 에러로 포함하도록 합니다.)
        position: naver.maps.Position.TOP_LEFT
      }
    });
  }

  /**
   * @description 지도에 이벤트를 설정합니다.
   * @param {string} event 이벤트명
   *  @param {()=void} callback 이벤트 콜백
   * @memberof NaverGeoblockMap
   */
  public setMapLister(event: string, callback: (e: any) => void) {
    if (this.map === null || this.map === undefined) return;
    this?.map?.addListener(event, callback);
  }

  /**
   * @description 옵션에 따라 지도에 표시할 마커의 아이콘을 반환합니다.
   * @param {string} status: 자전거의 상태를 나타냅니다.
   * @param {number} vendor: 자전거의 vendor (1: 세그웨이 맥스, 2: 네오, 3: 플러스, 4: 세그웨이, 5: 3.0)
   * @param {boolean} isSelected: 마커가 선택되었는지 여부를 나타냅니다.
   * @param {string} markerType: 마커의 종류를 나타냅니다.
   * @returns { url: string, size: naver.maps.Size }
   * @memberof NaverMap
   **/
  static getMapIcon({
    status,
    vendor,
    isSelected,
    markerType,
    count,
    size
  }: {
    status?: string;
    vendor?: number;
    isSelected?: boolean;
    markerType: string;
    count?: number;
    size?: number;
  }) {
    if (markerType === "bike_status") {
      const getBikeIcon = (
        status: string,
        vendor: number,
        isSelected?: boolean
      ) => {
        let bikeType = "";
        switch (vendor) {
          case 2:
            bikeType = "bicycle_1"; // 네오
            break;
          case 3:
            bikeType = "bicycle_3"; // 플러스
            break;
          case 5:
            bikeType = "bicycle_5"; // 3.0
            break;
          case 4:
          case 1:
            bikeType = "kickboard"; // 세그웨이, 세그웨이 맥스
            break;
          default:
            bikeType = "bicycle"; // 클래식
            break;
        }

        return `${IMAGE_MARKER_URL}/${bikeType}/marker/${status}${
          isSelected ? "-selected" : ""
        }.png`;
      };

      return {
        url: getBikeIcon(status!, vendor!, isSelected),
        scaledSize: new naver.maps.Size(20, 20)
      };
    }
    if (markerType === "clustering") {
      return {
        content: `
          <div class="cluster-marker"
            style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px;
                padding: 0px;
                border: 0px solid transparent;
                position: absolute;
                width: ${size ?? 30}px;
                height: ${size ?? 30}px;
                left: 0px;
                top: 0px;
                color: #fff;
                background: rgb(109 109 109 / 80%);
                font-weight: 700;
                border-radius: 100%;
            "
        >
          ${count}
          </div>`,
        size: new naver.maps.Size(35, 35),
        anchor: new naver.maps.Point(17, 17)
      };
    }
    if (markerType === "destination_start") {
      return {
        url: `${IMAGE_MARKER_URL}/destination/marker/riding_start.png`,
        size: new naver.maps.Size(40, 40)
      };
    }

    if (markerType === "destination_end") {
      return {
        url: `${IMAGE_MARKER_URL}/destination/marker/riding_end.png`,
        size: new naver.maps.Size(40, 40)
      };
    }

    if (markerType === "track_point") {
      return {
        url: `${IMAGE_MARKER_URL}/location/marker/dynamo.png`,
        size: new naver.maps.Size(40, 40),
        anchor: naver.maps.Position.BOTTOM_CENTER
      };
    }
    return { url: "" };
  }

  /**
   * @description 자전거의 상태에 따라 표시할 텍스트를 반환합니다.
   * @param {string} status: 자전거의 상태를 나타냅니다.
   * @returns {string} 자전거의 상태에 따른 텍스트
   * @memberof NaverMap
   */
  static getBikeStatus = (status: string) => {
    switch (status) {
      case "LAV":
        return "배터리 부족";
      case "BRD":
        return "사용자 탑승중";
      case "LRD":
        return "사용자 탑승중";
      case "LNP":
        return "고장 확인 필요";
      case "BNP":
        return "고장 확인 필요";
      case "LNB":
        return "재배치 필요";
      case "BNB":
        return "재배치 필요";
      case "BB":
        return "재배치중";
      case "LB":
        return "재배치중";
      case "BP":
        return "수리중";
      case "LP":
        return "수리중";
    }
  };

  /**
   * @description position이 [lng, lat] 순서로 되어있는 경우, [lat, lng] 순서로 변경합니다.
   * @param {Position} position: 좌표
   * @returns {Position}
   */
  protected adjustPosition(position: number[]) {
    if (!position) return [];
    return position[0] > position[1] ? [position[1], position[0]] : position;
  }

  /**
   * @description Position으로 부터 LatLng 좌표를 반환합니다. (lng, lat 순서가 바뀌어 있는 경우, 순서를 바꿔서 반환합니다.)
   * @param {Position} position: 좌표
   * @returns {naver.maps.LatLng}
   * @memberof NaverMap
   */
  public getLatLngFromPosition(position: Position) {
    const adjustedPosition = this.adjustPosition(position);
    return new naver.maps.LatLng(adjustedPosition?.[0], adjustedPosition?.[1]);
  }

  /**
   * @description 지도에 정보가 달린 윈도우를 그립니다.
   * @param {string | number} info: 윈도우에 표시할 정보
   * @param {naver.maps.Marker} marker: 윈도우를 표시할 마커
   * @param {naver.maps.InfoWindow} infoWindow: 윈도우
   * @memberof NaverMap
   */
  public drawWindowInfo(info: string | number, position?: Position) {
    const infoWindow = new naver.maps.InfoWindow({
      content: `<div style="width:100px;text-align:center;word-break:break-all;"><b>${info}</b></div>`,
      position: position ? this.getLatLngFromPosition(position) : undefined
    });
    return infoWindow;
  }

  /**
   * @description 지도에 마커를 그립니다.
   * @param {MarkerInfo} item: 마커에 대한 정보
   * @returns {naver.maps.Marker}
   */
  public drawMarker(item: MarkerInfo) {
    const markerOption = NaverMap.getMapIcon({
      status: item.status,
      vendor: item.vendor,
      isSelected: item.isSelected,
      markerType: item.markerType ?? "bike_status",
      count: item.count,
      size: item.size
    });

    return new naver.maps.Marker({
      position: this.getLatLngFromPosition(item.position),
      map: this.map as naver.maps.Map,
      icon: markerOption,
      title: "bike_marker"
    });
  }

  /**
   * @description 윈도우가 달린 마커를 지도에 표시하고, 해당하는 마커 그룹과 윈도우 그룹에 할당합니다.
   * @param {InfoWindowMarker} item: 마커와 윈도우 인포 대한 정보
   * @param {Record<string, naver.maps.Marker>} markerGroup: 마커 그룹
   * @param {Record<string, naver.maps.InfoWindow>} infoWindowGroup: 윈도우 그룹
   * @memberof NaverMap
   */
  public setWindowInfoMarker(
    item: InfoWindowMarker,
    markerGroup: Record<string, naver.maps.Marker>,
    infoWindowGroup: Record<string, naver.maps.InfoWindow>
  ) {
    const markerOption = NaverMap.getMapIcon({
      status: item.status,
      vendor: item.vendor,
      isSelected: item.isSelected,
      markerType: item.markerType ?? "bike_status"
    });
    const newMarker = this.drawMarker({
      ...item,
      position: item.position,
      option: markerOption,
      isSelected: false,
      markerType: item.markerType ?? "bike_status",
      status: item.status
    });

    const markerKey = item.uuid;

    markerGroup[markerKey] = newMarker;
    infoWindowGroup[markerKey] = this.drawWindowInfo(item.info, item.position);

    this.addMarkerClickEvent(newMarker, () => {
      this.toggleInfoWindow(newMarker, infoWindowGroup[markerKey]);
      this.setCenter(item.position);
    });

    return newMarker;
  }

  public removeMarker(
    uuid: string,
    markerGroup: Record<string, naver.maps.Marker>,
    infoWindowGroup?: Record<string, naver.maps.InfoWindow>
  ) {
    if (markerGroup[uuid] === undefined) return;

    markerGroup[uuid].setMap(null);
    infoWindowGroup && infoWindowGroup[uuid].setMap(null);
    delete markerGroup[uuid];
    infoWindowGroup && delete infoWindowGroup[uuid];
  }

  /**
   * @description 지도에 중심을 이동합니다.
   * @param {Position} position: 중심 좌표
   * @memberof NaverMap
   */
  public setCenter(position?: Position | null) {
    if (!position) return;
    this.map && this.map.setCenter(this.getLatLngFromPosition(position));
  }

  /**
   * @description 마커에 클릭 이벤트를 추가합니다.
   * @param {naver.maps.Marker} marker: 클릭 이벤트를 추가할 마커
   * @param {() => void} clickCallback: 클릭 이벤트 콜백 함수
   */
  public addMarkerClickEvent(
    marker: naver.maps.Marker,
    clickCallback: () => void
  ) {
    naver.maps.Event.addListener(marker, "click", clickCallback);
  }

  /**
   * @description 폴리라인을 그립니다.
   * @param {Position[]} positions: 폴리라인을 그릴 좌표 목록
   * @returns {naver.maps.Polyline}
   * @memberof NaverMap
   */
  public drawPolyline(
    positions: Position[],
    polylineOption?: Partial<PolygonOption>
  ) {
    return new naver.maps.Polyline({
      map: this.map,
      path: positions.map((position) => this.getLatLngFromPosition(position)),
      ...DEFAULT_POLYLINE_OPTION,
      ...polylineOption
    });
  }

  /**
   * @description 폴리곤을 그립니다
   * @param {Position[]} lines: 폴리곤을 그릴 좌표 목록
   * @param {PolygonOption} option: 폴리곤 옵션
   */
  public drawPolygon(lines: Position[], option = DEFAULT_POLYGON_OPTION) {
    return new naver.maps.Polygon({
      map: this.map,
      paths: lines.map((line) =>
        this.getLatLngFromPosition(line)
      ) as unknown as naver.maps.ArrayOfCoords[],
      ...option
    });
  }

  /**
   * 구멍난 폴리곤을 그립니다.
   */
  public drawHolePolygon(
    coordinates: Position[][],
    option = DEFAULT_POLYGON_OPTION,
    callBack?: (e: any, polygon: naver.maps.Polygon) => void
  ) {
    return new naver.maps.Polygon({
      map: this.map,
      paths: coordinates.map((line) =>
        line.map((position) => this.getLatLngFromPosition(position))
      ) as unknown as naver.maps.ArrayOfCoords[],
      ...option
    });
  }

  /**
   * @description 지도에 그려진 반납 구역 폴리곤을 그리고, geoblockPolygons에 할당합니다.
   * @param {ReturnGeoblock[]} geoblockPolygons: 반납 구역 목록
   * @memberof NaverMap
   */
  public setGeoblocks(
    geoblocks?: Geoblock[],
    options?: Partial<PolygonOption>,
    clickCallback?: (
      e: any,
      polygon: naver.maps.Polygon,
      polygon_id?: number
    ) => void
  ) {
    geoblocks?.forEach((block) => {
      const isActive = block.is_active;
      const polygonOption = {
        ...DEFAULT_POLYGON_OPTION,
        fillColor: isActive ? "#ff2100" : "#000000",
        strokeColor: isActive ? "#ff2100" : "#000000",
        ...options
      };

      if (!this.geoblockPolygons[block.polygon_id]) {
        const polygon = this.drawHolePolygon(
          block.polygon.coordinates,
          polygonOption,
          clickCallback
        );
        this.geoblockPolygons[block.polygon_id] = polygon;
        if (clickCallback) {
          naver.maps.Event.addListener(polygon, "click", (e) => {
            clickCallback(e, polygon, block.polygon_id);
          });
        }
      }
    });
  }

  /**
   * @description 지도에 그려진 반납 구역 폴리곤을 제거합니다.
   * @memberof NaverMap
   */
  public removeAllGeoblocks() {
    Object.values(this.geoblockPolygons)?.forEach((block) => {
      block.setMap(null);
    });
    this.geoblockPolygons = {};
  }

  /**
   * @description 제거할 반납구역 id를 받아서, 지도에 그려진 반납 구역 폴리곤을 제거합니다.
   * @param {Geoblock[]} geoblockList: 제거할 반납구역 아이디 목록
   * @memberof NaverMap
   */
  public removeGeoblockList(geoblockIdList: string[]) {
    geoblockIdList.forEach((id) => {
      this.geoblockPolygons[id].setMap(null);
      delete this.geoblockPolygons[id];
    });
  }

  /**
   * @description 새로운 반납구역 폴리곤과 현재, geoblockPolygons의 uuid를 비교하여, 없어져야할 반납구역 폴리곤 uuid 목록을 반환합니다.
   * @param {Geoblock[]} geoblockList: 새로운 반납구역 폴리곤 목록
   * @returns {string[]} 제거할 반납구역 polygon_id 목록
   */
  public getRemoveTargetGeoblockIds(newGeoblockList?: Geoblock[]) {
    const newGeoblockIds = newGeoblockList?.map(
      (block) => `${block.polygon_id}` // string으로 변환
    );
    const currentGeoblockIds = Object.keys(this.geoblockPolygons);

    // new에 있지 않은 current의 id를 반환
    return currentGeoblockIds.filter((id) => !newGeoblockIds?.includes(id));
  }

  /**
   * @description 지도를 destroy하여 제거합니다.
   * @memberof NaverMap
   */
  public destroy() {
    this.map?.destroy();
    this.map = undefined;
  }

  /**
   * @description 지도에 그려진 윈도우를 제거합니다.
   * @param {naver.maps.InfoWindow} infoWindow: 제거할 윈도우
   * @memberof NaverMap
   */
  public removeInfoWindow(infoWindow: naver.maps.InfoWindow) {
    infoWindow.setMap(null);
  }

  /**
   * @description 윈도우 상태에 따라 윈도우를 열거나 닫습니다.
   * @param {naver.maps.Marker} marker: 마커
   * @param {naver.maps.InfoWindow} infoWindow: 윈도우
   */
  public toggleInfoWindow(
    marker: naver.maps.Marker,
    infoWindow: naver.maps.InfoWindow
  ) {
    if (infoWindow.getMap()) {
      // 윈도우가 열려 있다면 닫습니다.
      infoWindow.close();
    } else {
      // 윈도우가 닫혀 있다면 엽니다.
      infoWindow.open(this.map!, marker);
      this.map!.panTo(marker.getPosition(), {});
    }
  }

  /**
   * @description 윈도우를 엽니다.
   * @param marker 마커
   * @param infoWindow 오픈할 윈도우
   */
  public openWindow(
    marker: naver.maps.Marker,
    infoWindow: naver.maps.InfoWindow
  ) {
    infoWindow.open(this.map!, marker);
  }
  /**
   * @description 지도에 줌 단계를 올립니다.
   * @param {number} num: 줌 단계, default로 줌이 2씩 증가합니다.
   * @memberof DashboardMap
   */
  zoomIn(num = 2) {
    this.map?.setZoom(this.map.getZoom() + num);
  }
  /**
   * @description 지도에 줌 단계를 설정합니다.
   * @param zoom 줌 단계
   * @memberof NaverMap
   */

  setZoom(zoom: number) {
    this.map?.setZoom(zoom);
  }
}

export default NaverMap;
