export const transformCrontabDataToString = (data: {
  min: number;
  hour: number;
  days: number[];
  dayOfMonth?: string;
  month?: string;
}) => {
  const crontab: string[] = [];

  crontab[0] = String(data?.min);
  crontab[1] = String(data?.hour);
  crontab[2] = data?.dayOfMonth ? data.dayOfMonth : "*"; //일
  crontab[3] = data?.month ? data.month : "*"; //월
  crontab[4] =
    data?.days?.length === 7 || data?.days?.length === 0
      ? "*"
      : data?.days.join(",");

  return crontab.join(" ");
};

export const OPTIONS_DAYS = [
  { value: 1, label: "월" },
  { value: 2, label: "화" },
  { value: 3, label: "수" },
  { value: 4, label: "목" },
  { value: 5, label: "금" },
  { value: 6, label: "토" },
  { value: 7, label: "일" }
];

export const transformStringToCrontabData = (data: string) => {
  const sprededData = data.split(" ");
  const crontab = {
    days:
      sprededData[4] === "*"
        ? [1, 2, 3, 4, 5, 6, 7]
        : sprededData[4].split(",").map((day) => Number(day === "0" ? 7 : day)), // cover 0 to 7
    dayOfMonth: sprededData[2],
    hour: Number(sprededData[1]),
    min: Number(sprededData[0])
  };

  return crontab;
};

export const transformCrontabDataToCharacters = (data: string) => {
  const sprededData = transformStringToCrontabData(data);
  const convertedDay = convertDayToCharacter(sprededData.days);

  const crontab = {
    days: convertedDay,
    dayOfMonth: sprededData.dayOfMonth,
    hour: sprededData.hour.toString().padStart(2, "0"),
    min: sprededData.min.toString().padStart(2, "0")
  };

  return crontab;
};

export const convertDayToCharacter = (days: number[]) => {
  if (days.length === 7) return "매일";
  const _days: string[] = [];

  days.sort().forEach((day) => {
    switch (day) {
      case 0: // cover 0 to 7
      case 7:
        _days.push("일");
        break;
      case 1:
        _days.push("월");
        break;
      case 2:
        _days.push("화");
        break;
      case 3:
        _days.push("수");
        break;
      case 4:
        _days.push("목");
        break;
      case 5:
        _days.push("금");
        break;
      case 6:
        _days.push("토");
        break;
    }
  });

  return _days.join(", ");
};
