import { MapUtility, Position } from "../../interfaces";
import { IMAGE_MARKER_URL } from "./constants/map";

export class NaverMapUtility implements MapUtility {
  /**
   * @description 옵션에 따라 지도에 표시할 마커의 아이콘을 반환합니다.
   * @param {string} status: 자전거의 상태를 나타냅니다.
   * @param {number} vendor: 자전거의 vendor (1: 세그웨이 맥스, 2: 네오, 3: 플러스, 4: 세그웨이, 5: 3.0)
   * @param {boolean} isSelected: 마커가 선택되었는지 여부를 나타냅니다.
   * @param {string} markerType: 마커의 종류를 나타냅니다.
   * @returns { url: string, size: naver.maps.Size }
   * @memberof NaverMap
   **/
  static getMapIcon({
    status,
    vendor,
    isSelected,
    markerType,
    count,
    size
  }: {
    status?: string;
    vendor?: number;
    isSelected?: boolean;
    markerType: string;
    count?: number;
    size?: number;
  }) {
    if (markerType === "bike_status") {
      const getBikeIcon = (
        status: string,
        vendor: number,
        isSelected?: boolean
      ) => {
        let bikeType = "";
        switch (vendor) {
          case 2:
            bikeType = "bicycle_1"; // 네오
            break;
          case 3:
            bikeType = "bicycle_3"; // 플러스
            break;
          case 5:
            bikeType = "bicycle_5"; // 3.0
            break;
          case 4:
          case 1:
            bikeType = "kickboard"; // 세그웨이, 세그웨이 맥스
            break;
          default:
            bikeType = "bicycle"; // 클래식
            break;
        }

        return `${IMAGE_MARKER_URL}/${bikeType}/marker/${status}${
          isSelected ? "-selected" : ""
        }.png`;
      };

      return {
        url: getBikeIcon(status!, vendor!, isSelected),
        scaledSize: new naver.maps.Size(20, 20)
      };
    }
    if (markerType === "clustering") {
      return {
        content: `
            <div class="cluster-marker"
              style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0px;
                  padding: 0px;
                  border: 0px solid transparent;
                  position: absolute;
                  width: ${size ?? 30}px;
                  height: ${size ?? 30}px;
                  left: 0px;
                  top: 0px;
                  color: #fff;
                  background: rgb(109 109 109 / 80%);
                  font-weight: 700;
                  border-radius: 100%;
              "
          >
            ${count}
            </div>`,
        size: new naver.maps.Size(35, 35),
        anchor: new naver.maps.Point(17, 17)
      };
    }
    if (markerType === "destination_start") {
      return {
        url: `${IMAGE_MARKER_URL}/destination/marker/riding_start.png`,
        size: new naver.maps.Size(40, 40)
      };
    }

    if (markerType === "destination_end") {
      return {
        url: `${IMAGE_MARKER_URL}/destination/marker/riding_end.png`,
        size: new naver.maps.Size(40, 40)
      };
    }

    if (markerType === "track_point") {
      return {
        url: `${IMAGE_MARKER_URL}/location/marker/dynamo.png`,
        size: new naver.maps.Size(40, 40),
        anchor: naver.maps.Position.BOTTOM_CENTER
      };
    }
    return { url: "" };
  }

  /**
   * @description 자전거의 상태에 따라 표시할 텍스트를 반환합니다.
   * @param {string} status: 자전거의 상태를 나타냅니다.
   * @returns {string} 자전거의 상태에 따른 텍스트
   * @memberof NaverMap
   */
  static getBikeStatus = (status: string) => {
    switch (status) {
      case "LAV":
        return "배터리 부족";
      case "BRD":
        return "사용자 탑승중";
      case "LRD":
        return "사용자 탑승중";
      case "LNP":
        return "고장 확인 필요";
      case "BNP":
        return "고장 확인 필요";
      case "LNB":
        return "재배치 필요";
      case "BNB":
        return "재배치 필요";
      case "BB":
        return "재배치중";
      case "LB":
        return "재배치중";
      case "BP":
        return "수리중";
      case "LP":
        return "수리중";
    }
  };

  /**
   * @description position이 [lng, lat] 순서로 되어있는 경우, [lat, lng] 순서로 변경합니다.
   * @param {Position} position: 좌표
   * @returns {Position}
   */
  static adjustPosition(position: number[]) {
    if (!position) return [];
    return position[0] > position[1] ? [position[1], position[0]] : position;
  }

  /**
   * @description Position으로 부터 LatLng 좌표를 반환합니다. (lng, lat 순서가 바뀌어 있는 경우, 순서를 바꿔서 반환합니다.)
   * @param {Position} position: 좌표
   * @returns {naver.maps.LatLng}
   * @memberof NaverMap
   */
  static getLatLngFromPosition(position: Position) {
    const adjustedPosition = NaverMapUtility.adjustPosition(position);
    return new naver.maps.LatLng(adjustedPosition?.[0], adjustedPosition?.[1]);
  }

  /**
   * @description 지도에 중심을 이동합니다.
   * @param {Position} position: 중심 좌표
   * @memberof NaverMap
   */
  static setCenter(map: naver.maps.Map, position?: Position | null) {
    if (!position) return;
    map && map.setCenter(NaverMapUtility.getLatLngFromPosition(position));
  }
  /**
   * @description 지도에 줌 단계를 올립니다.
   * @param {number} num: 줌 단계, default로 줌이 2씩 증가합니다.
   * @memberof DashboardMap
   */
  static zoomIn(map: naver.maps.Map, num = 2) {
    map.setZoom(map.getZoom() + num);
  }
  /**
   * @description 지도에 줌 단계를 설정합니다.
   * @param zoom 줌 단계
   * @memberof NaverMap
   */

  static setZoom(map: naver.maps.Map, zoom: number) {
    map.setZoom(zoom);
  }

  /**
   * @description 중심에서 부터, 지도의 꼭지점까지의 거리를 km로 구합니다.
   * @returns {number} km 단위의 거리
   * @memberof NaverDashboardMap
   */
  static getRadius(map: naver.maps.Map) {
    const center = map.getCenter();
    const centerCoord = new naver.maps.LatLng(center?.y, center?.x);
    const bounds = map.getBounds() as naver.maps.LatLngBounds;
    const northEast = bounds.getNE();
    const distanceByMeter =
      map.getProjection()?.getDistance(centerCoord, northEast) ?? 0;

    return distanceByMeter / 1000;
  }

  /**
   * @description 현재 지도의 zoom level을 구합니다.
   * @returns {number} 현재 지도의 zoom level
   * @memberof NaverDashboardMap
   */
  static getZoomLevel(map: naver.maps.Map) {
    return map.getZoom();
  }

  /**
   * @description 현재 지도의 중심 좌표를 구합니다.
   * @returns {number[]} 현재 지도의 중심 좌표
   * @memberof NaverDashboardMap
   */
  static getCenter(map: naver.maps.Map) {
    return [map.getCenter().x, map.getCenter().y];
  }

  /**
   * @description 현재 지도의 줌 값에 따라, eps 값을 산출합니다.
   * @param {number} currentZoom: 현재 지도의 줌 값
   * @memberof NaverDashboardMap
   * @returns eps(클러스터로 취급할 점과 점 사이의 거리)
   */
  static getZoomToEps(currentZoom: number) {
    const STANDARD_ZOOM = 16; // 줌 16 레벨이 기본이라고 가정
    const STANDARD_EPS = 0.16; // 줌 16 레벨일 때 eps 값

    const STOP_CLUSTERING_ZOOM = 9;
    const MAX_EPS = 20;

    const level = STANDARD_ZOOM - currentZoom;
    const calculatedEps = STANDARD_EPS * 2 ** level;

    return currentZoom <= STOP_CLUSTERING_ZOOM ? MAX_EPS : calculatedEps;
  }

  /**
   * @description 클러스터 api 호출시 사용할 필터 옵션을 구합니다.
   * @returns {object} 클러스터 api 호출시 사용할 필터 옵션
   */
  static getFilterOptions(map: naver.maps.Map) {
    return {
      eps: NaverMapUtility.getZoomToEps(NaverMapUtility.getZoomLevel(map)),
      position: NaverMapUtility.getCenter(map) ?? [],
      radius: NaverMapUtility.getRadius(map),
      zoom: NaverMapUtility.getZoomLevel(map)
    };
  }

  /**
   * @description 지도에 센터가 바뀌면 콜백을 실행합니다.
   * @param {(args?: any) => void} f: 콜백 함수
   * @memberof NaverDashboardMap
   */
  static addEventListenerByCenterChange(
    map: naver.maps.Map,
    f: (args?: any) => void
  ) {
    naver.maps.Event.addListener(map, "center_changed", f);
  }

  /**
   * @description 지도에 줌이 바뀌면 콜백을 실행합니다.
   * @param {(args?: any) => void} f: 콜백 함수
   * @memberof NaverDashboardMap
   */
  static addEventListerByZoomChange(
    map: naver.maps.Map,
    f: (args?: any) => void
  ) {
    naver.maps.Event.addListener(map, "zoom_changed", f);
  }
}
