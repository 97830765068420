const transformPhoneParams = (params: { q?: string }) => {
  let formatedParams;
  if (params.q) {
    const q = params.q;
    let phone;
    if (q.match(/\d{3}-\d{4}-\d{4}/)) {
      phone = q.slice(q.indexOf("-") + 1);
    }
    formatedParams = { ...params, q: phone ?? q };
  }
  return formatedParams ?? params;
};

export default transformPhoneParams;
