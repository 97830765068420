const getDeviceInfo = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") !== -1;
  const isIos = ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1;
  const isWechat = ua.indexOf("micromessenger") !== -1;
  const isPc = !isAndroid && !isIos && !isWechat;
  const isMac = ua.indexOf("macintosh") !== -1;
  const isWindows = ua.indexOf("windows") !== -1;

  return {
    ua,
    isAndroid,
    isIos,
    isWechat,
    isPc,
    isMac,
    isWindows,
    isOther: !isAndroid && !isIos && !isWechat && !isPc && !isMac && !isWindows
  };
};

export default getDeviceInfo;
