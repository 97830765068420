import { NaverMapUtility } from "./NaverMapUtility";
import { SHOW_CLUSTER_MARKER_ZOOM, SHOW_MARKER_ZOOM } from "./constants/map";

import { v1 } from "uuid";
import { transformToKst } from "../../data";
import {
  Bike,
  BikeCluster,
  InfoWindowMarker,
  MapMarker,
  MapRidingTrackPoint,
  MarkerInfo,
  Position
} from "../../interfaces";

export class NaverMarker implements MapMarker {
  public clusterMarkers: Record<string, naver.maps.Marker> = {};
  public bikeMarkers: Record<string, naver.maps.Marker> = {};
  public bikeWindowInfos: Record<string, naver.maps.InfoWindow> = {};
  public selectedBikeMarker: naver.maps.Marker | null = null;
  public markerDrawIdentifier: string | null = null; // 마커를 그리는 요청을 취소하기 위한 flag 값

  public trackPointInfoWindows: Record<string, naver.maps.InfoWindow> = {};
  public startPointMarker: naver.maps.Marker | null = null;
  public endPointMarker: naver.maps.Marker | null = null;
  public trackPointsMarkers: Record<string, naver.maps.Marker> = {};

  constructor(private map: naver.maps.Map | null) {
    this.map = map;
  }

  /**
   * @description 지도에 정보가 달린 윈도우를 그립니다.
   * @param {string | number} info: 윈도우에 표시할 정보
   * @param {naver.maps.Marker} marker: 윈도우를 표시할 마커
   * @param {naver.maps.InfoWindow} infoWindow: 윈도우
   * @memberof NaverMap
   */
  public drawWindowInfo(info: string | number, position?: Position) {
    const infoWindow = new naver.maps.InfoWindow({
      content: `<div style="width:100px;text-align:center;word-break:break-all;"><b>${info}</b></div>`,
      position: position
        ? NaverMapUtility.getLatLngFromPosition(position)
        : undefined
    });
    return infoWindow;
  }

  /**
   * @description 지도에 마커를 그립니다.
   * @param {MarkerInfo} item: 마커에 대한 정보
   * @returns {naver.maps.Marker}
   */
  public drawMarker(item: MarkerInfo) {
    const markerOption = NaverMapUtility.getMapIcon({
      status: item.status,
      vendor: item.vendor,
      isSelected: item.isSelected,
      markerType: item.markerType ?? "bike_status",
      count: item.count,
      size: item.size
    });

    return new naver.maps.Marker({
      position: NaverMapUtility.getLatLngFromPosition(item.position),
      map: this.map as naver.maps.Map,
      icon: markerOption,
      title: "bike_marker"
    });
  }
  /**
   * @description 윈도우가 달린 마커를 지도에 표시하고, 해당하는 마커 그룹과 윈도우 그룹에 할당합니다.
   * @param {InfoWindowMarker} item: 마커와 윈도우 인포 대한 정보
   * @param {Record<string, naver.maps.Marker>} markerGroup: 마커 그룹
   * @param {Record<string, naver.maps.InfoWindow>} infoWindowGroup: 윈도우 그룹
   * @memberof NaverMap
   */
  public setWindowInfoMarker(
    item: InfoWindowMarker,
    markerGroup: Record<string, naver.maps.Marker>,
    infoWindowGroup: Record<string, naver.maps.InfoWindow>
  ) {
    const markerOption = NaverMapUtility.getMapIcon({
      status: item.status,
      vendor: item.vendor,
      isSelected: item.isSelected,
      markerType: item.markerType ?? "bike_status"
    });
    const newMarker = this.drawMarker({
      ...item,
      position: item.position,
      option: markerOption,
      isSelected: false,
      markerType: item.markerType ?? "bike_status",
      status: item.status
    });

    const markerKey = item.uuid;

    markerGroup[markerKey] = newMarker;
    infoWindowGroup[markerKey] = this.drawWindowInfo(item.info, item.position);

    this.addMarkerClickEvent(newMarker, () => {
      this.toggleInfoWindow(newMarker, infoWindowGroup[markerKey]);
      NaverMapUtility.setCenter(this.map!, item.position);
    });

    return newMarker;
  }

  public removeMarker(
    uuid: string,
    markerGroup: Record<string, naver.maps.Marker>,
    infoWindowGroup?: Record<string, naver.maps.InfoWindow>
  ) {
    if (markerGroup[uuid] === undefined) return;

    markerGroup[uuid].setMap(null);
    infoWindowGroup && infoWindowGroup[uuid].setMap(null);
    delete markerGroup[uuid];
    infoWindowGroup && delete infoWindowGroup[uuid];
  }

  /**
   * @description 마커에 클릭 이벤트를 추가합니다.
   * @param {naver.maps.Marker} marker: 클릭 이벤트를 추가할 마커
   * @param {() => void} clickCallback: 클릭 이벤트 콜백 함수
   */
  public addMarkerClickEvent(
    marker: naver.maps.Marker,
    clickCallback: () => void
  ) {
    naver.maps.Event.addListener(marker, "click", clickCallback);
  }

  /**
   * @description 지도에 그려진 윈도우를 제거합니다.
   * @param {naver.maps.InfoWindow} infoWindow: 제거할 윈도우
   * @memberof NaverMap
   */
  public removeInfoWindow(infoWindow: naver.maps.InfoWindow) {
    infoWindow.setMap(null);
  }

  /**
   * @description 윈도우 상태에 따라 윈도우를 열거나 닫습니다.
   * @param {naver.maps.Marker} marker: 마커
   * @param {naver.maps.InfoWindow} infoWindow: 윈도우
   */
  public toggleInfoWindow(
    marker: naver.maps.Marker,
    infoWindow: naver.maps.InfoWindow
  ) {
    if (infoWindow.getMap()) {
      // 윈도우가 열려 있다면 닫습니다.
      infoWindow.close();
    } else {
      // 윈도우가 닫혀 있다면 엽니다.
      infoWindow.open(this.map!, marker);
      this.map!.panTo(marker.getPosition(), {});
    }
  }

  /**
   * @description 윈도우가 달린 바이크 마커를 그리고, bikeMarkers와 bikeWindowInfos에 할당합니다. 마커를 클릭하면 선택되며, clickCallback이 실행됩니다.
   * @param {Bike} bike: 바이크 정보
   * @param {(bike: Bike) => void} [clickCallback]: 마커 클릭시 실행할 콜백 함수
   * @param {string} [currentIdentifier]: 현재 그리기 작업의 식별자
   * @returns {naver.maps.Marker} 그려진 바이크 마커
   * @memberof NaverDashboardMap
   */
  setBikeMarker(
    bike: Bike & { isSelected?: boolean },
    clickCallback?: (bike: Bike) => void,
    currentIdentifier?: string
  ) {
    if (currentIdentifier && this.markerDrawIdentifier !== currentIdentifier) {
      return;
    }

    const uuid = bike.sn;

    if (this.bikeMarkers[uuid]) return; // 중복으로 그리는 것 방지
    const item: InfoWindowMarker = {
      uuid: bike.sn,
      position: bike.location,
      info: `${bike.sn}`,
      markerType: "bike_status",
      vendor: bike.vendor,
      status: bike.status,
      isSelected: bike.isSelected
    };
    const marker = this.setWindowInfoMarker(
      item,
      this.bikeMarkers,
      this.bikeWindowInfos
    );
    this.bikeMarkers[uuid] = marker;

    this.addMarkerClickEvent(marker, () => {
      clickCallback && clickCallback(bike);

      const prevMarker = this.selectedBikeMarker;
      prevMarker && this.updateIconNotSelected(prevMarker);

      marker && this.updateIconSelected(marker);
      this.selectedBikeMarker = marker;
    });

    return marker;
  }

  /**
   * @description 바이크 마커의 아이콘을 selected 상태로 변경합니다.
   * @param {naver.maps.Marker} marker: 바이크 마커
   * @memberof NaverDashboardMap
   */
  updateIconSelected(marker: naver.maps.Marker) {
    const prevMarker = marker;
    const icon = prevMarker?.getIcon() as naver.maps.ImageIcon;

    icon?.url &&
      prevMarker?.setIcon({
        url: icon?.url!.replace(".png", "-selected.png"),
        scaledSize: new naver.maps.Size(20, 20)
      });
  }

  /**
   * @description 바이크 마커의 아이콘을 selected가 아닌 상태로 변경합니다.
   * @param {naver.maps.Marker} marker: 바이크 마커
   * @memberof NaverDashboardMap
   */
  updateIconNotSelected(marker: naver.maps.Marker) {
    const prevMarker = marker;
    const icon = prevMarker?.getIcon() as naver.maps.ImageIcon;

    icon?.url &&
      prevMarker?.setIcon({
        url: icon?.url!.replace("-selected", ""),
        scaledSize: new naver.maps.Size(20, 20)
      });
  }

  /**
   * @description 윈도우를 엽니다.
   * @param marker 마커
   * @param infoWindow 오픈할 윈도우
   */
  public openWindow(
    marker: naver.maps.Marker,
    infoWindow: naver.maps.InfoWindow
  ) {
    infoWindow.open(this.map!, marker);
  }

  /**
   * @description 선택된 바이크 정보로, 마커를 업데이트합니다. 마커가 없다면 새로 그리고, 있다면 정보를 갱신합니다.
   * @param {Bike} bike: 바이크 정보
   * @param {(bike: Bike) => void} [callBack]: 마커 클릭시 실행할 콜백 함수
   */
  updateSelectedBike(
    bike: Bike & { isSelected: boolean },
    callBack?: (bike: Bike) => void
  ) {
    const setZoomIn = () => {
      const { zoom } = NaverMapUtility.getFilterOptions(this.map!);
      if (zoom && zoom < SHOW_MARKER_ZOOM)
        this.map?.setZoom(SHOW_MARKER_ZOOM, true);
    };

    const setCenterToBike = () => {
      const position = NaverMapUtility.getLatLngFromPosition(bike.location);
      this.map?.setCenter(position);
    };

    const updateUnselectPrevMarker = () => {
      const prevSelected = this.selectedBikeMarker;
      prevSelected && this.updateIconNotSelected(prevSelected);
    };

    const makeNewMarker = () => {
      const newMarker = this.setBikeMarker(
        {
          ...bike,
          isSelected: true
        },
        callBack
      );
      if (newMarker) {
        this.selectedBikeMarker = newMarker;
        this.updateIconSelected(newMarker);
        newMarker.setZIndex(1);
        const newWindowInfo = this.bikeWindowInfos[bike.sn];
        newWindowInfo && this.openWindow(newMarker, newWindowInfo);
      }
    };

    const updateMarker = (marker: naver.maps.Marker) => {
      const icon = NaverMapUtility.getMapIcon({
        ...bike,
        markerType: "bike_status",
        isSelected: true
      });
      const position = NaverMapUtility.getLatLngFromPosition(bike.location);
      marker.setIcon(icon);
      marker.setPosition(position);
      marker.setZIndex(1);
      this.selectedBikeMarker = marker;
      const windowInfo = this.bikeWindowInfos[bike.sn];
      windowInfo && this.openWindow(marker, windowInfo);
    };

    const updateMarkerByExist = () => {
      const marker = this.bikeMarkers[bike.sn];

      if (!marker) {
        makeNewMarker();
      } else {
        updateMarker(marker);
      }
      this.selectedBikeMarker && openSelectedWindow(this.selectedBikeMarker);
    };

    const openSelectedWindow = (marker: naver.maps.Marker) => {
      const timeout = setTimeout(() => {
        const windowInfo = this.bikeWindowInfos[bike.sn];
        windowInfo && this.openWindow(marker, windowInfo);
        clearTimeout(timeout);
      }, 1000);
    };

    setCenterToBike();
    setZoomIn();
    updateUnselectPrevMarker();
    updateMarkerByExist();
  }

  /**
   * @description 제너레이터 함수로 바이크 마커들을 그립니다.
   * @param {Bike[]} bikes: 바이크 정보 목록
   * @param {(bike: Bike) => void} [clickCallback]: 마커 클릭시 실행할 콜백 함수
   * @param {string} [currentIdentifier]: 현재 그리는 마커의 식별자
   * @memberof NaverDashboardMap
   */
  *setBikeMarkers(
    bikes: Bike[],
    clickCallback?: (bike: Bike) => void,
    currentIdentifier?: string
  ) {
    let index = 0;

    while (index < bikes.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const bike = bikes[index];
        this.setBikeMarker(bike, clickCallback, currentIdentifier);
        index++;
        yield;
      } else {
        break;
      }
    }
  }

  /**
   * @description 모든 바이크 마커를 제거하고, 초기화합니다.
   * @memberof NaverDashboardMap
   */
  getRemoveAllBikeMarkers() {
    Object.keys(this.bikeMarkers).forEach((key) => {
      this.bikeMarkers[key].setMap(null);
      delete this.bikeMarkers[key];
    });
  }

  /**
   * @description 모든 바이크 마커의 윈도우를 닫습니다.
   * @memberof NaverDashboardMap
   */
  public closeAllBikeMarkerInfoWindows() {
    Object.values(this.bikeWindowInfos).forEach((info) => info.close());
  }

  /**
   * @description 클러스터 마커의 uuid를 구합니다.
   * @param {BikeCluster} bikeCluster: 클러스터 정보
   * @returns {string} 클러스터 마커의 uuid
   */
  getClusterUuid(bikeCluster: BikeCluster) {
    return bikeCluster.count + "-" + bikeCluster.marker_location.join(",");
  }

  /**
   * @description 클러스터 갯수에 따라 마커의 크기를 결정합니다.
   * @param {number} bikeClusterCount: 클러스터 갯수
   * @returns {number} 마커의 크기
   * @memberof NaverDashboardMap
   */
  getSizeOfClusterMarker(bikeClusterCount: number) {
    return bikeClusterCount < 10
      ? 35
      : bikeClusterCount < 20
      ? 40
      : bikeClusterCount < 50
      ? 45
      : bikeClusterCount < 100
      ? 50
      : bikeClusterCount < 500
      ? 55
      : 60;
  }
  /**
   * @description 줌레벨에 따라, 클러스터 마커와 바이크 마커용 데이터를 분류합니다.
   * @param {BikeCluster[]} clusterGroup: 클러스터 정보
   * @returns {clusters: BikeCluster[], bikes: Bike[]} 분류된 클러스터 데이터와 바이크 데이터
   * @memberof NaverDashboardMap
   */
  classifyCluterGroupAndBikesByZoom(clusterGroup: BikeCluster[]) {
    if (!clusterGroup) return { clusters: [], bikes: [] };
    const zoomLevel = NaverMapUtility.getZoomLevel(this.map!) as number;
    const clusterAndBikes: { clusters: BikeCluster[]; bikes: Bike[] } = {
      clusters: [],
      bikes: []
    };

    if (zoomLevel >= SHOW_MARKER_ZOOM) {
      clusterAndBikes.bikes = clusterGroup.flatMap((cluster) =>
        cluster.cluster.map((bike) => bike)
      );
    }

    if (zoomLevel < SHOW_MARKER_ZOOM && zoomLevel >= SHOW_CLUSTER_MARKER_ZOOM) {
      // SHOW_MARKER_ZOOM 이상 SHOW_CLUSTER_MARKER_ZOOM 미만이면 1개 클러스터링 그룹은 바이크 마커로 표시하고 나머지는 클러스터 마커로 표시
      clusterAndBikes.clusters = clusterGroup.filter(
        (cluster) => cluster.count > 1
      );

      clusterAndBikes.bikes = clusterGroup
        .filter((cluster) => cluster.count === 1)
        .flatMap((cluster) => cluster.cluster.map((bike) => bike));
    }

    if (zoomLevel < SHOW_CLUSTER_MARKER_ZOOM) {
      clusterAndBikes.clusters = clusterGroup;
    }

    return clusterAndBikes;
  }
  /**
   * @description 제너레이터 함수로 새로운 바이크 리스트를 받아, 지울 대상을 선별하고, 바이크 마커를 제거합니다.
   * @param {Bike[]} newBikes: 새로운 바이크 정보 목록
   * @param {string} [currentIdentifier]: 현재 그리는 마커의 식별자
   * @memberof NaverDashboardMap
   */
  *removeBikeMarkersComparedToNewBikes(
    newBikes: Bike[],
    currentIdentifier?: string
  ) {
    let index = 0;
    const previousBikeMarkerLength = Object.keys(this.bikeMarkers).length;
    const removeTargetUuids = Object.keys(this.bikeMarkers).filter(
      (uuid) => !newBikes.find((bike) => bike.sn + "" === uuid)
    );

    while (index < previousBikeMarkerLength) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const uuid = removeTargetUuids[index];

        this.removeMarker(uuid, this.bikeMarkers, this.bikeWindowInfos);
        index++;
        yield;
      } else {
        break;
      }
    }
  }
  /**
   * @description 클러스터 마커를 그리고, clusterMarkers에 할당합니다.
   * @param {BikeCluster} bikeCluster: 클러스터 정보
   * @param {string} [currentIdentifier]: 마커 그리기 식별자
   * @returns {naver.maps.Marker} 그려진 클러스터 마커
   * @memberof NaverDashboardMap
   */
  setClusterMarker(bikeCluster: BikeCluster, currentIdentifier?: string) {
    if (currentIdentifier && this.markerDrawIdentifier !== currentIdentifier) {
      return;
    }

    const uuid = this.getClusterUuid(bikeCluster);
    if (this.clusterMarkers[uuid]) return; // 중복으로 그리는 것 방지
    const marker = this.drawMarker({
      position: bikeCluster.marker_location,
      markerType: "clustering",
      count: bikeCluster.count,
      isSelected: false,
      size: this.getSizeOfClusterMarker(bikeCluster.count)
    });

    if (!marker) return;
    this.addMarkerClickEvent(marker, () => {
      NaverMapUtility.setCenter(this.map!, bikeCluster.marker_location);
      NaverMapUtility.zoomIn(this.map!); // 클러스터 마커 클릭시, 지도 확대
    });

    this.clusterMarkers[uuid] = marker;
    return marker;
  }

  /**
   * @description 제너레이터 함수로 클러스터 마커들을 그리고, clusterMarkers에 할당합니다.
   * @param {BikeCluster[]} bikeClusters: 클러스터 정보 목록
   * @memberof NaverDashboardMap
   */
  setClusterMarkers(bikeClusters: BikeCluster[], currentIdentifier?: string) {
    let index = 0;

    while (index < bikeClusters.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const bikeCluster = bikeClusters[index];
        this.setClusterMarker(bikeCluster, currentIdentifier);
        index++;
      } else {
        break;
      }
    }
  }

  /**
   * @description 새로운 클러스터링 리스트를 받아, 지울 대상을 선별하고, 클러스터링 마커를 제거합니다.
   * @param {string} uuid: 클러스터링 마커의 uuid
   * @memberof NaverDashboardMap
   */
  removeClusterMarkersComparedToNewClusters(
    newClusters: BikeCluster[],
    currentIdentifier?: string
  ) {
    const newClusterUuids = newClusters.map((cluster) => {
      return this.getClusterUuid(cluster);
    });

    const removeTargetUuids = Object.keys(this.clusterMarkers).filter(
      (uuid) => !newClusterUuids.includes(uuid)
    );

    let index = 0;
    while (index < removeTargetUuids.length) {
      if (currentIdentifier === this.markerDrawIdentifier) {
        const uuid = removeTargetUuids[index];
        this.removeMarker(uuid, this.clusterMarkers);
        index++;
      } else {
        break;
      }
    }
  }

  /**
   * @description 클러스터링 리스트를 받아, 바이크 마커와, 클러스터 마커를 업데이트합니다.
   * @param {BikeCluster[]} bikeClusterList: 클러스터링 리스트
   * @param {(bike: Bike) => void} setSeletedBike: 선택된 바이크 정보를 업데이트하는 함수
   * @memberof NaverDashboardMap
   */
  updateBikeAndClusterMarkers = (
    bikeClusterList: BikeCluster[],
    setSeletedBike: (bike: Bike) => void
  ) => {
    const { bikes, clusters } =
      this.classifyCluterGroupAndBikesByZoom(bikeClusterList); // 클러스터링 대상이 아닌 자전거들만 가져오기
    this.removeClusterMarkersComparedToNewClusters(clusters);
    this.cancelAndExcuteMarkerDraw(bikes, clusters, setSeletedBike);
  };

  /**
   * @description 바이크와 클러스터 마커를 제너레이터로 지연시켜 그립니다.
   * @param bikes 바이크 리스트
   * @param clusters 클러스터 리스트
   * @param setSeletedBike 선택된 바이크 정보를 업데이트하는 함수
   * @memberof NaverDashboardMap
   */
  cancelAndExcuteMarkerDraw(
    bikes: Bike[],
    clusters: BikeCluster[],
    setSeletedBike: (bike: Bike) => void
  ) {
    const currentIdentifier = v1();
    this.markerDrawIdentifier = currentIdentifier; // 새로운 마커를 그리기 위해, 현재의 identifier를 저장합니다.

    const removeBikeIterator = this.removeBikeMarkersComparedToNewBikes(
      bikes,
      currentIdentifier
    );

    const setBikeInterator = this.setBikeMarkers(
      bikes,
      setSeletedBike,
      currentIdentifier
    );

    const makeExcuteIteration =
      (
        iterable: Generator | Generator<Promise<unknown>, void, unknown> | null
      ) =>
      () => {
        const done = iterable?.next().done;

        if (!done && this.markerDrawIdentifier === currentIdentifier) {
          setTimeout(makeExcuteIteration(iterable), 0);
        } else {
          iterable = null;
          return;
        }
      };

    makeExcuteIteration(removeBikeIterator)();
    makeExcuteIteration(setBikeInterator)();

    this.removeClusterMarkersComparedToNewClusters(clusters, currentIdentifier);
    this.setClusterMarkers(clusters, currentIdentifier);
  }

  /**
   * @description 시작지점을 지도에 표시하고 startPointMarker에 할당합니다.
   * @param {Position} position: 시작지점의 좌표
   * @memberof NaverMap
   */
  public setStartPointMarker(position: Position) {
    const newMarker = this.drawMarker({
      position: position,
      markerType: "destination_start"
    });
    this.startPointMarker = newMarker;
  }

  /**
   * @description 도착지점을 지도에 표시하고 endPointMarker에 할당합니다.
   * @param {Position} position: 도착지점의 좌표
   * @memberof NaverMap
   */
  public setEndPointMarker(position: Position) {
    const newMarker = this.drawMarker({
      position: position,
      markerType: "destination_end"
    });
    this.endPointMarker = newMarker;
  }

  /**
   * @description 윈도우가 달린 클릭가능한 GPS 트랙포인트를 지도에 표시하고, trackPointsMarkers에 할당합니다.
   * @param {MapRidingTrackPoint[]} trackPoints: GPS 트랙포인트 목록
   * @memberof NaverMap
   */
  public setTrackPointsWindowMarkers(trackPoints: MapRidingTrackPoint[]) {
    trackPoints.forEach((trackPoint) => {
      this.setWindowInfoMarker(
        {
          ...trackPoint,
          uuid: trackPoint._id,
          position: trackPoint.position,

          info: `<div style="font-size: 11px">${[
            "위치: " +
              NaverMapUtility.adjustPosition(trackPoint.position).join(
                "<br />"
              ),
            trackPoint.timestamp
              ? "시간: " + transformToKst(trackPoint.timestamp)
              : undefined,
            trackPoint.locked ? "locked: " + trackPoint.locked : undefined,
            trackPoint.bike_battery
              ? "배터리: " + trackPoint.bike_battery + "%"
              : undefined
          ].join("<br />")}</div>`,
          markerType: "track_point"
        },
        this.trackPointsMarkers,
        this.trackPointInfoWindows
      );
    });
  }
  /**
   * @description uuid로, 트랙 포인트 마커를 찾아, 해당 윈도우를 엽니다.
   * @param {string | number} uuid: 트랙 포인트의 uuid: _id
   * @param {naver.maps.Marker} marker: 마커
   * @param {naver.maps.InfoWindow} infoWindow: 윈도우
   */
  public openTrackPointInfoWindow(uuid: string | number) {
    if (!this.trackPointInfoWindows[uuid]) return;
    const marker = this.trackPointsMarkers[uuid];

    this.trackPointInfoWindows[uuid].open(this.map as naver.maps.Map, marker);
    this.map?.panTo(this.trackPointInfoWindows[uuid].getPosition(), {});
  }

  /**
   * @description 지도에 표시된 GPS 트랙포인트 마커를 모두 제거합니다.
   * @memberof NaverMap
   */
  public removeAllTrackPointsMarkers() {
    Object.entries(this.trackPointsMarkers)?.forEach(([key, marker]) => {
      marker.setMap(null);
      // 윈도우도 같이 제거합니다.
      if (this.trackPointInfoWindows[key]) {
        const info = this.trackPointInfoWindows[key];
        info.setMap(null);
        delete this.trackPointInfoWindows[key];
      }
    });
    this.trackPointsMarkers = {};
  }

  /**
   * @description 지도에 표시된 시작지점 마커를 제거합니다.
   * @memberof NaverMap
   */
  public removeStartPointMarker() {
    this.startPointMarker?.setMap(null);
    this.startPointMarker = null;
  }

  /**
   * @description 지도에 표시된 도착지점 마커를 제거합니다.
   * @memberof NaverMap
   */
  public removeEndPointMarker() {
    this.endPointMarker?.setMap(null);
    this.endPointMarker = null;
  }

  /**
   * @description 모든 트랙포인트 윈도우를 닫습니다.
   * @memberof NaverMap
   */
  public closeAllTrackPointInfoWindows() {
    Object.values(this.trackPointInfoWindows).forEach((info) => info.close());
  }

  /**
   * @description 지도를 클릭했을 때 모든 윈도우를 닫습니다.
   * @memberof NaverMap
   */
  public closeWindowByClickMapEvent() {
    naver.maps.Event.addListener(this.map as naver.maps.Map, "click", () => {
      this.closeAllTrackPointInfoWindows();
    });
  }
}
