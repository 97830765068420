import { DrawingManagerOptions, MapDrawingManager } from "../../interfaces";
import {
  DEFAULT_DRAWINGMAGAGER_ANCHORS,
  DEFAULT_WAITIMG_POLYGON_OPTION
} from "./constants/map";

export class NaverDrawingManager implements MapDrawingManager {
  public drawingManager: any | null = null;
  private map?: naver.maps.Map | undefined;
  private isDrawing: boolean = false;

  constructor(map: naver.maps.Map) {
    this.map = map;
  }

  /**
   * @description 드로잉메니저 컨트롤러를 생성합니다.
   * @memberof NaverDrawingManagerMap
   */
  public setDrawingManager(callback: () => void) {
    this.drawingManager = new naver.maps.drawing.DrawingManager({
      map: this.map,
      drawingControl: [],
      controlPointOptions:
        DEFAULT_DRAWINGMAGAGER_ANCHORS as naver.maps.drawing.ControlPointOptions
    });

    callback && callback();
  }

  /**
   * @description 드로잉메니저 컨트롤러를 제거합니다.
   * @memberof NaverDrawingManagerMap
   */
  public removeDrawingController() {
    this.setDrawingController({
      drawingControl: false
    });
  }

  /**
   * @description 드로잉메니저 초기 드로잉 모드를 설정합니다.
   * @memberof NaverDrawingManagerMap
   */
  public setDefultDrawingMode(mode = naver.maps.drawing.DrawingMode.POLYGON) {
    this.setDrawingController({
      drawingMode: mode,
      polygonOptions: {
        ...DEFAULT_WAITIMG_POLYGON_OPTION,
        zIndex: 3,
        paths: []
      }
    });
  }

  /**
   * @description 드로잉메니저 옵션을 설정합니다.
   * @param {DrawingManagerOptions} options 드로잉매니저 컨트롤 옵션
   * @memberof NaverDrawingManagerMap
   */
  public setDrawingController(options?: DrawingManagerOptions) {
    if (!this.drawingManager) return;

    this.drawingManager.setOptions(
      options
        ? options
        : {
            drawingControl: [
              naver.maps.drawing.DrawingMode.POLYGON,
              naver.maps.drawing.DrawingMode.RECTANGLE
            ],
            drawingControlOptions: {
              position: naver.maps.Position.RIGHT_CENTER,
              style: naver.maps.drawing.DrawingStyle.VERTICAL
            },
            rectangleOptions: {
              ...DEFAULT_WAITIMG_POLYGON_OPTION
            },
            polygonOptions: {
              ...DEFAULT_WAITIMG_POLYGON_OPTION
            }
          }
    );
  }

  /**
   * @description 드로잉메니저에 이벤트를 설정합니다.
   * @param {string} event 이벤트명
   *  @param {()=void} callback 이벤트 콜백
   * @memberof NaverDrawingManager   */
  public setDrawingManagerLister(
    event: string,
    callback: (e: naver.maps.drawing.DrawingEvents) => void
  ) {
    if (this.drawingManager === null || this.drawingManager === undefined)
      return;
    this?.drawingManager?.addListener(event, callback);
  }

  /**
   * @description 수정가능한 신규 폴리곤을 추가합니다.
   * @param {naver.maps.ArrayOfCoords[]} paths 폴리곤의 좌표
   * @param {(e)=>void} callback 폴리곤 생성 후 콜백
   * @memberof NaverDrawingManager   */
  public forkEditablePolygon(
    paths: naver.maps.ArrayOfCoords[],
    callback: (polygon: naver.maps.Polygon) => void
  ) {
    const editablePolygon = new naver.maps.Polygon({
      map: this.map,
      paths: [...paths],
      ...DEFAULT_WAITIMG_POLYGON_OPTION,
      zIndex: 2,
      clickable: false
    });

    callback && callback(editablePolygon);

    this.drawingManager.addDrawing(
      editablePolygon,
      naver.maps.drawing.DrawingMode.POLYGON
    );

    return editablePolygon;
  }

  /**
   * @description 수정가능한 폴리곤을 제거합니다.
   * @param {naver.maps.Polygon[]} polygon 제거할 폴리곤
   * @memberof NaverDrawingManager   */
  public removeEditablePolygon(polygon: naver.maps.Polygon) {
    if (!this.drawingManager) return;
    this.drawingManager?.removeDrawing(polygon);
  }

  /**
   * @description drawingManager를 제거합니다.
   * @memberof NaverDrawingManager   */
  public destroy() {
    if (!this.drawingManager) return;
    this.drawingManager?.setMap(null);
    this.drawingManager = null;
  }

  /**
   * @description 드로잉모드를 설정합니다.
   * @param {boolean} isDrawing 드로잉모드
   */

  public setDrawingMode(isDrawing: boolean) {
    this.isDrawing = isDrawing;
  }

  /**
   * @description 드로잉모드를 반환합니다.
   */
  public getDrawingMode() {
    return this.isDrawing;
  }
}

export default NaverDrawingManager;
