import { SVGProps } from "react";
import "./spinner.style.scss";
import React from "react";

type LoadingIconProps = {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  spin?: boolean;
};

export const SpinnerIcon = ({
  className,
  width = 24,
  height = 24,
  color = "currentColor",
  spin = true,
  ...props
}: LoadingIconProps & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      className={`${spin ? "spin" : ""} ${className}`}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        opacity="0.65"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.62857C11.5757 7.62857 11.2286 7.28143 11.2286 6.85714V3.77143C11.2286 3.34714 11.5757 3 12 3C12.4243 3 12.7714 3.34714 12.7714 3.77143V6.85714C12.7714 7.28143 12.4243 7.62857 12 7.62857Z"
        fill={color}
      />
      <path
        opacity="0.75"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1857 8.21426C13.8182 8.00211 13.6912 7.52794 13.9034 7.16049L15.4462 4.48826C15.6584 4.1208 16.1325 3.99377 16.5 4.20591C16.8674 4.41806 16.9945 4.89223 16.7823 5.25969L15.2395 7.93191C15.0273 8.29937 14.5532 8.4264 14.1857 8.21426Z"
        fill={color}
      />
      <path
        opacity="0.85"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7857 9.81426C15.5736 9.44681 15.7006 8.97263 16.0681 8.76049L18.7403 7.21763C19.1078 7.00549 19.5819 7.13252 19.7941 7.49998C20.0062 7.86743 19.8792 8.3416 19.5117 8.55375L16.8395 10.0966C16.4721 10.3087 15.9979 10.1817 15.7857 9.81426Z"
        fill={color}
      />
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3714 12.0001C16.3714 11.5758 16.7185 11.2286 17.1428 11.2286H20.2285C20.6528 11.2286 21 11.5758 21 12.0001C21 12.4244 20.6528 12.7715 20.2285 12.7715H17.1428C16.7185 12.7715 16.3714 12.4244 16.3714 12.0001Z"
        fill={color}
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7857 14.1858C15.9979 13.8183 16.4721 13.6913 16.8395 13.9034L19.5117 15.4463C19.8792 15.6584 20.0062 16.1326 19.7941 16.5001C19.5819 16.8675 19.1078 16.9945 18.7403 16.7824L16.0681 15.2395C15.7006 15.0274 15.5736 14.5532 15.7857 14.1858Z"
        fill={color}
      />
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1857 15.7857C14.5532 15.5736 15.0273 15.7006 15.2395 16.0681L16.7823 18.7403C16.9945 19.1078 16.8674 19.5819 16.5 19.7941C16.1325 20.0062 15.6584 19.8792 15.4462 19.5117L13.9034 16.8395C13.6912 16.4721 13.8182 15.9979 14.1857 15.7857Z"
        fill={color}
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.3715C12.4243 16.3715 12.7714 16.7186 12.7714 17.1429V20.2286C12.7714 20.6529 12.4243 21 12 21C11.5757 21 11.2286 20.6529 11.2286 20.2286V17.1429C11.2286 16.7186 11.5757 16.3715 12 16.3715Z"
        fill={color}
      />
      <path
        opacity="0.35"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81426 15.7857C10.1817 15.9979 10.3087 16.4721 10.0966 16.8395L8.55375 19.5117C8.3416 19.8792 7.86743 20.0062 7.49998 19.7941C7.13252 19.5819 7.00549 19.1078 7.21763 18.7403L8.76049 16.0681C8.97263 15.7006 9.44681 15.5736 9.81426 15.7857Z"
        fill={color}
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21426 14.1858C8.4264 14.5532 8.29937 15.0274 7.93191 15.2395L5.25969 16.7824C4.89223 16.9945 4.41806 16.8675 4.20591 16.5001C3.99377 16.1326 4.1208 15.6584 4.48826 15.4463L7.16049 13.9034C7.52794 13.6913 8.00211 13.8183 8.21426 14.1858Z"
        fill={color}
      />
      <path
        opacity="0.45"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62857 12.0001C7.62857 12.4244 7.28143 12.7715 6.85714 12.7715H3.77143C3.34714 12.7715 3 12.4244 3 12.0001C3 11.5758 3.34714 11.2286 3.77143 11.2286H6.85714C7.28143 11.2286 7.62857 11.5758 7.62857 12.0001Z"
        fill={color}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21426 9.81426C8.00211 10.1817 7.52794 10.3087 7.16049 10.0966L4.48826 8.55375C4.1208 8.3416 3.99377 7.86743 4.20591 7.49998C4.41806 7.13252 4.89223 7.00549 5.25969 7.21763L7.93191 8.76049C8.29937 8.97263 8.4264 9.44681 8.21426 9.81426Z"
        fill={color}
      />
      <path
        opacity="0.55"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81426 8.21426C9.44681 8.4264 8.97263 8.29937 8.76049 7.93191L7.21763 5.25969C7.00549 4.89223 7.13252 4.41806 7.49998 4.20591C7.86743 3.99377 8.3416 4.1208 8.55375 4.48826L10.0966 7.16049C10.3087 7.52794 10.1817 8.00211 9.81426 8.21426Z"
        fill={color}
      />
    </svg>
  );
};
